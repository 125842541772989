import React from 'react';
import { string, arrayOf } from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Marquee } from '../Marquee';

const renderWord = (word, key) => (
    <span key={key}>
        &nbsp;
        &nbsp;
        &nbsp;
        {word}
    </span>
);

export const Keywords = ({ content }) => {
    const firstHalf = content.slice(0, content.length / 2);
    const secondHalf = content.slice(content.length / 2);

    return (
        <div className="keywords">
            <Fade right distance="50px">
                <Marquee>
                    {firstHalf.map(renderWord)}
                </Marquee>
            </Fade>
            <Fade right distance="100px" delay={100}>
                <Marquee duration={30}>
                    {secondHalf.map(renderWord)}
                </Marquee>
            </Fade>
        </div>
    );
};

Keywords.propTypes = {
    content: arrayOf(string).isRequired,
};
