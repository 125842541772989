import React from 'react';
import { string, arrayOf } from 'prop-types';
import Fade from 'react-reveal/Fade';

const renderIcon = (p, key) => (
    <Fade key={key} delay={100 * (key + 1)}>
        <img
            className="platforms__icon"
            src={require(`../../assets/icon-${p}.svg`)}
            alt={p}
        />
    </Fade>
);

export const Platforms = ({ platforms }) => {
    return (
        <Fade>
            <div className="platforms">
                {platforms.map(renderIcon)}
            </div>
        </Fade>
    );
};

Platforms.propTypes = {
    platforms: arrayOf(string).isRequired,
};
