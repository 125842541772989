import React from 'react';

const routes = ['What we do', 'Works', 'About us'];
const makeIdFromRoute = route =>
    route.split(' ').map(word => word.toLowerCase()).join('-');

export const HeroNavigation = ({ onClick }) => (
    <div className="nav">
        {routes.map((route, key) => (
            <a
                className="nav__item"
                href={`#${makeIdFromRoute(route)}`}
                onClick={onClick}
                key={key}
            >
                {route}
            </a>
        ))}
        <a
            className="nav__item"
            href="https://blog.theevenstar.net/"
            target="_blank"
            rel="noreferrer"
        >
            Blog
        </a>
    </div>
);
