import React from 'react';
import { shape, func } from 'prop-types';
import { Feature, contentPropTypes } from '../Feature';
import { Projects, projectsPropType } from '../Projects';

export class OurWorks extends React.Component {
    static propTypes = {
        content: shape(contentPropTypes),
        projects: projectsPropType,
        onSelectProject: func,
    };

    render() {
        const {
            content,
            projects,
            onSelectProject,
        } = this.props;

        return (
            <div id="works" className="our-works">
                <div className="our-works__feature">
                    <Feature { ...content } />
                </div>
                <div className="our-works__projects">
                    <Projects
                      slides={projects}
                      onSelect={onSelectProject}
                    />
                </div>
            </div>
        );
    }
}
