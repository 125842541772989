import React from 'react';
import {HeroLogoWebGL} from "./webgl";

export class HeroLogo extends React.PureComponent {

    container = React.createRef();
    logoWebGL = null;

    componentWillUpdate = (nextProps, nextState, nextContext) => {
        Reflect.deleteProperty(this, 'logoWebGL');
    };

    componentDidMount = () => {
        this.logoWebGL = new HeroLogoWebGL();
        if (this.container && this.container.current) {
            this.container.current.appendChild(this.logoWebGL.canvas);
        }
    };

    render = () => (
        <div ref={this.container} className="hero-logo" key="logo-canvas"/>
    );
}

