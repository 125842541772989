import React from 'react';
import cn from 'classnames';

export class Modal extends React.Component {
    componentDidMount() {
        window.addEventListener('keydown', this.handleClose);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleClose);
    }

    handleClose = event => {
        if (event.code === 'Escape' && this.props.show) {
            this.props.onClose();
        }
    };

    render() {
        const { show, children, hide } = this.props;
        const className = cn('modal', {
            'modal--open': show,
            'modal--hide': hide,
        });

        return (
            <div className={className}>
                {show && children}
            </div>
        );
    }
}
