import React from 'react';
import {
    string,
    arrayOf,
    shape,
    oneOfType,
    func,
} from 'prop-types';
import Fade from 'react-reveal/Fade';
import { withSlider, sliderPropTypes } from '../SliderHOC';
import { SliderControls } from '../SliderControls';

const Project = ({ project, onSelect }) => (
    <div className="project" onClick={onSelect}>
        <div className="project__image">
            <div className="project__image-cover"/>
            <img
                src={project.preview}
                alt={project.code}
            />
        </div>
        <div className="project__description project-description">
            <div className="project-description__details">
                <h3 className="project-description__title">
                  {project.title}
                </h3>
                <p className="project-description__stack">
                    {project.stack.join(', ')}
                </p>
            </div>
        </div>
    </div>
);

const Slider = ({
    slides,
    onSelect,
    currentSlide,
    onNextSlide,
    onPrevSlide,
}) => (
        <div className="projects">
            <div className="projects__controls">
                <SliderControls onNext={onNextSlide} onPrev={onPrevSlide} />
            </div>
            <div className="projects__slider">
                {slides.map((project, key) => (
                    <Fade
                        key={key}
                        spy={currentSlide}
                        when={currentSlide === key}
                        appear
                    >
                      {
                        (document.body.clientWidth > 768) ?
                          <Project
                            projectOrder={key}
                            currentSlide={currentSlide}
                            project={project}
                            onSelect={onSelect(key)}
                          /> :
                          <Project
                            projectOrder={key}
                            currentSlide={currentSlide}
                            project={project}
                            onSelect={onSelect(currentSlide)}
                          />
                      }
                    </Fade>
                ))}
            </div>
        </div>
);

export const Projects = withSlider(Slider);

const descriptionItemPropType = shape({
    type: string,
    content: oneOfType([string, arrayOf(string)]),
});

export const projectPropType = shape({
    id: string,
    title: string,
    stack: arrayOf(string),
    code: string,
    preview: string,
    description: arrayOf(descriptionItemPropType),
});

export const projectsPropType = arrayOf(projectPropType);

Projects.propTypes = {
    ...sliderPropTypes,
    slides: projectsPropType,
    onProjectClick: func,
};
