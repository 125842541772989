import React from 'react';
import { func, bool } from 'prop-types';
import cn from 'classnames';

export const SliderControls = ({ inverted, onNext, onPrev, onClose }) => {
    const className = cn('slider-controls', {
        'slider-controls--inverted': inverted,
    });

    return (
        <div className={className}>
            <button
                className="slider-controls__control slider-controls__control--left"
                onClick={onPrev}
            />
            <button
                className="slider-controls__control slider-controls__control--right"
                onClick={onNext}
            />
            {onClose && (
                <button
                    className="slider-controls__control slider-controls__control--close"
                    onClick={onClose}
                />
            )}
        </div>
    )
};

SliderControls.propTypes = {
    onPrev: func.isRequired,
    onNext: func.isRequired,
    onClose: func,
    inverted: bool,
};
