import React from 'react';
import cn from 'classnames';
import { HeroNavigation } from '../HeroNavigation';
import { Footer } from '../../Footer';

export class HeroMobileNavigation extends React.Component {
    state = {
        open: false,
        shouldInvert: false,
        currentScroll: 0,
    }

    componentWillUpdate(nextProps, nextState) {
        if (!this.state.open && nextState.open) {
            this.setState({
                currentScroll: window.scrollY,
            }, () => {
                window.addEventListener('scroll', this.preventScroll);
            });
        }

        if (this.state.open && !nextState.open) {
            window.removeEventListener('scroll', this.preventScroll);
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleToggle = () => {
        this.setState(({ open }) => ({ open: !open }));
    }

    handleClick = (event) => {
        this.handleToggle();
        this.props.onClick(event);
    }

    handleScroll = () => {
        const { shouldInvert } = this.state;
        const shouldInvertMenu = window.scrollY > (window.innerHeight - 56);

        if (shouldInvert !== shouldInvertMenu) {
            this.setState({ shouldInvert: shouldInvertMenu });
        }
    }

    preventScroll = () => {
        window.scrollTo(0, this.state.currentScroll);
    }

    render() {
        const { open, shouldInvert } = this.state;
        const { links, email } = this.props;
        const menuClassName = cn('m-nav__menu', {
            'm-nav__menu--close': open,
            'm-nav__menu--inverted': shouldInvert,
        });

        const sideClassName = cn('m-nav-side', {
            'm-nav-side--open': open,
        });

        return (
            [
                <div key="m-nav" className="m-nav" onClick={this.handleToggle}>
                    <div className={menuClassName}></div>
                </div>,
                <aside key="m-nav-side" className={sideClassName}>
                    <HeroNavigation onClick={this.handleClick} />
                    <Footer links={links} email={email} noID />
                </aside>
            ]
        );
    }
}
