import React from 'react';
import Typist from 'react-typist';
import Fade from 'react-reveal/Fade';
import LiquidButton from '../LiquidButton'
import {isMobile, isMobileDevice} from "../../../common/device";

export class HeroTitle extends React.PureComponent {

    constructor(props) {
        super(props);

        this.onResize = () => {
            this.forceUpdate();
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    render = ({ onCTAClick } = this.props) => {
      const showDelay = (isMobileDevice() && isMobile()) ? {
        firstBlock: 600,
        secondBlock: 1500,
        thirdBlock: 2500,
      } : {
        firstBlock: 1200,
        secondBlock: 3000,
        thirdBlock: 5000,
      };
      return (
        <div className="hero-title">
          <Fade top distance="50px" delay={showDelay.firstBlock}>
            <h1 className="hero-title__header" translate="no">
              The sky<br />is not the limit<br />when you
            </h1>
          </Fade>
          <Typist
            cursor={{ show: false }}
            avgTypingDelay={100}
            startDelay={showDelay.secondBlock}
          >
            <h2 className="hero-title__typing" translate="no">develop software</h2>
          </Typist>
          <Fade top distance="50px" delay={showDelay.thirdBlock}>
            <div className="hero-title__liquid">
              <LiquidButton>
                <a
                  className="hero-title__cta"
                  href="#footer"
                  onClick={onCTAClick}
                  translate="no"
                >
                  Get in touch
                </a>
              </LiquidButton>
            </div>
          </Fade>
        </div>
      );
    }
}
