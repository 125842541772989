import React from 'react';
import {appData} from './webgl/navier-stokes-solver';
import bg from '../../../assets/hero-bg.png';
import {isMobileDevice, isMobile} from "../../../common/device";

export class HeroBackground extends React.PureComponent {

    container = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        };

        window.addEventListener('resize', () => {
            this.setState({
                isMobile: isMobile()
            });

            this.update();
        });
    }

    componentWillUpdate = (nextProps, nextState, nextContext) => {
        if (nextProps.enabled) {
            //this.update();
        }
    };

    componentDidMount = () => {
        if (!appData.solver) {
            appData.init();
        }

        appData.onInit = this.update;

        if (this.props.enabled) {
            requestAnimationFrame(this.update);
        }
    };

    update = (time = 0) => {
        if (appData.solver && appData.solver.webGLBroken) {
            return false;
        }

        if (this.container && this.container.current && !isMobile() && !isMobileDevice() && appData.canvas) {
            this.container.current.appendChild(appData.canvas);
        }

        if (appData.solver) {
            let enabled = !(isMobile() || isMobileDevice());
            if (!appData.solver.enabled && enabled) {
                appData.solver.enabled = enabled;
                requestAnimationFrame((dt) => appData.solver.update(dt));
            } else {
                appData.solver.enabled = enabled;
            }

        }
    };

    render = () => (
        (isMobileDevice() || isMobile()) ? (
            <div className="hero-background" key="background-image">
                <img src={bg} alt="Background" aria-hidden={true} />
            </div>
        ) : (
            <div ref={this.container} className="hero-background" key="background-canvas"/>
        )
    );
}

