import bgCanvas from '../../../../assets/background/background.png';
import crystalsCanvas from '../../../../assets/background/crystals.png';
import rgbCanvas from '../../../../assets/background/rgb.png';

class pointerPrototype {
    constructor() {
        this.id = -1;
        this.x = 0;
        this.y = 0;
        this.dx = 0;
        this.dy = 0;
        this.down = false;
        this.moved = false;
        this.color = [0, 0, 0];
    }
}

class GLProgram {
    constructor(gl, vertexShader, fragmentShader) {
        this.gl = gl;
        this.uniforms = {};
        this.program = gl.createProgram();

        gl.attachShader(this.program, vertexShader);
        gl.attachShader(this.program, fragmentShader);
        gl.linkProgram(this.program);

        if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
            throw gl.getProgramInfoLog(this.program);
        }

        const uniformCount = gl.getProgramParameter(this.program, gl.ACTIVE_UNIFORMS);
        for (let i = 0; i < uniformCount; i++) {
            const uniformName = gl.getActiveUniform(this.program, i).name;
            this.uniforms[uniformName] = gl.getUniformLocation(this.program, uniformName);
        }
    }

    bind() {
        this.gl.useProgram(this.program);
    }
}

let assets = [];

let loadImage = (src) => {
    return new Promise((resolve) => {
        let image = new Image();
        image.src = src;
        image.onload = () => {
            resolve(image);
        }
    });
};

export const loadAssets = () => {
    return Promise.all([
        loadImage(bgCanvas),
        loadImage(rgbCanvas),
        loadImage(crystalsCanvas)
    ]).then((images) => {
        for(let image of images) {
            assets.push({image});
        }

        return Promise.resolve();
    });
};

export const appData = {
    solver: null,
    canvas: null,
    init: null,
    onInit: null
};

export class NavierStokesSolver {
    constructor(canvas) {
        this.assets = assets;
        this.canvas = canvas;
        this.pointers = [];
        this.splatStack = [];
        this.bloomFramebuffers = [];

        this.enabled = true;
        this.animationFrame = null;

        this.config = {
            SIM_RESOLUTION: 512,
            DYE_RESOLUTION: 1024,
            DENSITY_DISSIPATION: 0.978,
            VELOCITY_DISSIPATION: 0.99,
            PRESSURE_DISSIPATION: 0.35,
            PRESSURE_ITERATIONS: 10,
            CURL: 100,
            SPLAT_RADIUS: 0.8,
            SHADING: false,
            COLORFUL: true,
            PAUSED: true,
            BACK_COLOR: {r: 19, g: 18, b: 29},
            TRANSPARENT: false,
            BLOOM: true,
            BLOOM_ITERATIONS: 2,
            BLOOM_RESOLUTION: 512,
            BLOOM_INTENSITY: 0.15,
            BLOOM_THRESHOLD: 0.9,
            BLOOM_SOFT_KNEE: 0.99
        };

        this.lastPointerTime = 0;
        this.isPointerUpdating = false;
        this.lastDensityDiss = this.config.DENSITY_DISSIPATION;
        this.lastVelocityDiss = this.config.VELOCITY_DISSIPATION;
        this.lastPressureDiss = this.config.PRESSURE_DISSIPATION;
        this.lastRedrawTime = 0;

        this.webGLBroken = false;

        this.pointers.push(new pointerPrototype());

        const {gl, ext} = this.getWebGLContext(this.canvas);
        this.gl = gl;
        this.ext = ext;

        if (!gl) {
            return null;
        }

        if (this.isMobile()) {
            this.config.SHADING = false;
        }
        if (!ext.supportLinearFiltering) {
            this.config.SHADING = false;
            this.config.BLOOM = false;
        }

        // This.startStats();

        this.blit = (() => {
            gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
            gl.bufferData(
                gl.ARRAY_BUFFER,
                new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]),
                gl.STATIC_DRAW
            );
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, gl.createBuffer());
            gl.bufferData(
                gl.ELEMENT_ARRAY_BUFFER,
                new Uint16Array([0, 1, 2, 0, 2, 3]),
                gl.STATIC_DRAW
            );
            gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0);
            gl.enableVertexAttribArray(0);

            return (destination) => {
                gl.bindFramebuffer(gl.FRAMEBUFFER, destination);
                gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
            };
        })();

        this.simWidth = null;
        this.simHeight = null;
        this.dyeWidth = null;
        this.dyeHeight = null;
        this.density = null;
        this.velocity = null;
        this.divergence = null;
        this.curl = null;
        this.pressure = null;
        this.bloom = null;

        /*
         *This.ditheringTexture = this.createTextureAsync('LDR_RGB1_0.png');
         *this.evenstarLogo = this.createTextureAsync('./assets/evenstar.png');
         */
        this.textures = this.loadTextures(assets);
        this.ditheringTexture = this.textures[1];
        this.backgroundLogo = this.textures[0];
        this.crystalsLogo = this.textures[2];
        this.buildShaders();

        this.drawTextureProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.drawTextureShader
        );
        this.clearProgram = new GLProgram(gl, this.baseVertexShader, this.clearShader);
        this.colorProgram = new GLProgram(gl, this.baseVertexShader, this.colorShader);
        this.backgroundProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.backgroundShader
        );
        this.displayProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.displayShader
        );
        this.displayBloomProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.displayBloomShader
        );
        this.displayShadingProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.displayShadingShader
        );
        this.displayBloomShadingProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.displayBloomShadingShader
        );
        this.bloomPrefilterProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.bloomPrefilterShader
        );
        this.bloomBlurProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.bloomBlurShader
        );
        this.bloomFinalProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.bloomFinalShader
        );
        this.splatProgram = new GLProgram(gl, this.baseVertexShader, this.splatShader);
        this.advectionProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            ext.supportLinearFiltering
                ? this.advectionShader
                : this.advectionManualFilteringShader
        );
        this.divergenceProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.divergenceShader
        );
        this.curlProgram = new GLProgram(gl, this.baseVertexShader, this.curlShader);
        this.vorticityProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.vorticityShader
        );
        this.pressureProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.pressureShader
        );
        this.gradienSubtractProgram = new GLProgram(
            gl,
            this.baseVertexShader,
            this.gradientSubtractShader
        );

        this.initFramebuffers();

        this.lastColorChangeTime = Date.now();

        this.update();

        this.addEvents();

        if (appData.onInit) {
            appData.onInit();
        }
    }

    loadTextures(assets) {
        let res = [];
        const {gl,ext} = this;
        gl.activeTexture(gl.TEXTURE0);
        if (assets instanceof Array) {
            for (let i = 0; i < assets.length; i += 1) {
                let texture = gl.createTexture();
                gl.bindTexture(gl.TEXTURE_2D, texture);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                gl.texImage2D(
                    gl.TEXTURE_2D,
                    0,
                    ext.formatRGBA.internalFormat,
                    ext.formatRGBA.format,
                    ext.halfFloatTexType,
                    assets[i].image // New Uint8Array([255, 255, 255])
                );

                let obj = {
                    texture,
                    width: assets[i].image.width,
                    height: assets[i].image.height,
                    attach(id) {
                        gl.activeTexture(gl.TEXTURE0 + id);
                        gl.bindTexture(gl.TEXTURE_2D, texture);

                        return id;
                    }
                };
                res.push(obj);
            }
        } else {
            let texture = gl.createTexture();
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

            gl.texImage2D(
                gl.TEXTURE_2D,
                0,
                ext.formatRGBA.internalFormat,
                ext.formatRGBA.format,
                ext.halfFloatTexType,
                assets.image
            );

            let obj = {
                texture,
                width: assets.image.width,
                height: assets.image.height,
                attach(id) {
                    gl.activeTexture(gl.TEXTURE0 + id);
                    gl.bindTexture(gl.TEXTURE_2D, texture);

                    return id;
                }
            };

            return obj;
        }

        return res;
    }

    getWebGLContext(canvas) {
        const params = {
            alpha: true,
            depth: false,
            stencil: false,
            antialias: false,
            preserveDrawingBuffer: false,
            premultipliedAlpha: false
        };

        let gl = canvas.getContext('webgl2', params);
        const isWebGL2 = Boolean(gl);
        if (!isWebGL2) {
            gl =
                canvas.getContext('webgl', params) ||
                canvas.getContext('experimental-webgl', params);
        }

        if (!gl) {
            this.enabled = false;
            //document.location.reload(true);

            return false;
        }

        let halfFloat;
        let supportLinearFiltering;
        if (isWebGL2) {
            gl.getExtension('EXT_color_buffer_float');
            supportLinearFiltering = gl.getExtension('OES_texture_float_linear');
        } else {
            halfFloat = gl.getExtension('OES_texture_half_float');
            supportLinearFiltering = gl.getExtension('OES_texture_half_float_linear');
        }

        gl.clearColor(0.0, 0.0, 0.0, 1.0);

        const halfFloatTexType = isWebGL2 ? gl.HALF_FLOAT : ((halfFloat !== null) ? halfFloat.HALF_FLOAT_OES : gl.UNSIGNED_SHORT_4_4_4_4);
        let formatRGBA;
        let formatRG;
        let formatR;

        if (isWebGL2) {
            formatRGBA = this.getSupportedFormat(
                gl,
                gl.RGBA16F,
                gl.RGBA,
                halfFloatTexType
            );
            formatRG = this.getSupportedFormat(gl, gl.RG16F, gl.RG, halfFloatTexType);
            formatR = this.getSupportedFormat(gl, gl.R16F, gl.RED, halfFloatTexType);
        } else {
            formatRGBA = this.getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
            formatRG = this.getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
            formatR = this.getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
        }

        if (formatRGBA === null) {
        }
        // Ga('send', 'event', isWebGL2 ? 'webgl2' : 'webgl', 'not supported');
        else {
            // Ga('send', 'event', isWebGL2 ? 'webgl2' : 'webgl', 'supported');

            return {
                gl,
                ext: {
                    formatRGBA,
                    formatRG,
                    formatR,
                    halfFloatTexType,
                    supportLinearFiltering
                }
            };
        }

        return false;
    }

    getSupportedFormat(gl, internalFormat, format, type) {
        if (!this.supportRenderTextureFormat(gl, internalFormat, format, type)) {
            switch (internalFormat) {
                case gl.R16F:
                    return this.getSupportedFormat(gl, gl.RG16F, gl.RG, type);
                case gl.RG16F:
                    return this.getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, type);
                default:
                    return null;
            }
        }

        return {
            internalFormat,
            format
        };
    }

    supportRenderTextureFormat(gl, internalFormat, format, type) {
        let texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, 4, 4, 0, format, type, null);

        let fbo = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
        gl.framebufferTexture2D(
            gl.FRAMEBUFFER,
            gl.COLOR_ATTACHMENT0,
            gl.TEXTURE_2D,
            texture,
            0
        );

        const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
        if (status !== gl.FRAMEBUFFER_COMPLETE) {
            return false;
        }

        return true;
    }

    captureScreenshot() {
        this.colorProgram.bind();
        const gl = this.gl;
        gl.uniform4f(this.colorProgram.uniforms.color, 0, 0, 0, 1);
        this.blit(this.density.write.fbo);

        this.render(this.density.write.fbo);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.density.write.fbo);

        let length = this.dyeWidth * this.dyeHeight * 4;
        let pixels = new Float32Array(length);
        gl.readPixels(0, 0, this.dyeWidth, this.dyeHeight, gl.RGBA, gl.FLOAT, pixels);

        let newPixels = new Uint8Array(length);

        let id = 0;
        for (let i = this.dyeHeight - 1; i >= 0; i--) {
            for (let j = 0; j < this.dyeWidth; j++) {
                let nid = i * this.dyeWidth * 4 + j * 4;
                newPixels[nid + 0] = this.clamp01(pixels[id + 0]) * 255;
                newPixels[nid + 1] = this.clamp01(pixels[id + 1]) * 255;
                newPixels[nid + 2] = this.clamp01(pixels[id + 2]) * 255;
                newPixels[nid + 3] = this.clamp01(pixels[id + 3]) * 255;
                id += 4;
            }
        }

        let captureCanvas = document.createElement('canvas');
        let ctx = captureCanvas.getContext('2d');
        captureCanvas.width = this.dyeWidth;
        captureCanvas.height = this.dyeHeight;

        let imageData = ctx.createImageData(this.dyeWidth, this.dyeHeight);
        imageData.data.set(newPixels);
        ctx.putImageData(imageData, 0, 0);
        let datauri = captureCanvas.toDataURL();

        this.downloadURI('fluid.png', datauri);

        URL.revokeObjectURL(datauri);
    }

    clamp01(input) {
        return Math.min(Math.max(input, 0), 1);
    }

    downloadURI(filename, uri) {
        let link = document.createElement('a');
        link.download = filename;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    isMobile() {
        return (/Mobi|Android/i).test(navigator.userAgent);
    }

    compileShader(type, source) {
        const gl = this.gl;
        const shader = gl.createShader(type);
        gl.shaderSource(shader, source);
        gl.compileShader(shader);

        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            throw gl.getShaderInfoLog(shader);
        }

        return shader;
    }

    buildShaders() {
        const gl = this.gl;
        this.baseVertexShader = this.compileShader(
            gl.VERTEX_SHADER,
            `
    precision highp float;
    attribute vec2 aPosition;
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform vec2 texelSize;
    void main () {
        vUv = aPosition * 0.5 + 0.5;
        vL = vUv - vec2(texelSize.x, 0.0);
        vR = vUv + vec2(texelSize.x, 0.0);
        vT = vUv + vec2(0.0, texelSize.y);
        vB = vUv - vec2(0.0, texelSize.y);
        gl_Position = vec4(aPosition, 0.0, 1.0);
    }
`
        );

        this.drawTextureShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
            precision highp float;
            precision highp int;
            
            uniform highp sampler2D tex;
            uniform vec2 screenSize;
            uniform vec2 imageSize;
            varying vec2 vUv;
            uniform vec2 uvBias;
            uniform float uvScale;
            uniform int type; 
            // 0 - вписать текстуру по умолчанию
            // 1 - занять текстурой весь экран с обрезкой текстуры и сохранением aspect текстуры
            // 2 - вписать текстуру целиком в экран с сохранением aspect текстуры
            // 3 - вписать текстуру целиком в экран с сохранением aspect текстуры и с ручным смещением по UV
            
            // занять текстурой весь экран с обрезкой текстуры и сохранением aspect текстуры
            vec2 transformUVOuter(vec2 uv,float imageAspect,float screenAspect) {
                float horizontalDrawAspect = imageAspect / screenAspect;
                float verticalDrawAspect = 1.0;
                // does it fill horizontally?
                if (horizontalDrawAspect < 1.0) {
                    verticalDrawAspect /= horizontalDrawAspect;
                    horizontalDrawAspect = 1.0;
                }
                    vec2 UV;
                if (horizontalDrawAspect >1.0) {
                    UV.x = uv.x / horizontalDrawAspect + (0.5 - 1.0 / horizontalDrawAspect * 0.5);
                } else {
                    UV.x = uv.x; 
                }
                if (verticalDrawAspect >1.0) {
                    UV.y = uv.y / verticalDrawAspect + (0.5 - 1.0 / verticalDrawAspect * 0.5);
                } else {
                    UV.y = uv.y; 
                }
                return UV;
            }
            // вписать текстуру целиком в экран с сохранением aspect текстуры
            vec2 transformUVInner(vec2 uv,float imageAspect,float screenAspect) {
                float horizontalDrawAspect = imageAspect / screenAspect;
                float verticalDrawAspect = 1.0;
                // does it fill horizontally?
                if (horizontalDrawAspect > 1.0) {
                    verticalDrawAspect /= horizontalDrawAspect;
                    horizontalDrawAspect = 1.0;
                }
                    vec2 UV;
                if (horizontalDrawAspect <1.0) {
                    UV.x = uv.x / horizontalDrawAspect + (0.5 - 1.0 / horizontalDrawAspect * 0.5);
                } else {
                    UV.x = uv.x; 
                }
                if (verticalDrawAspect <1.0) {
                    UV.y = uv.y / verticalDrawAspect + (0.5 - 1.0 / verticalDrawAspect * 0.5);
                } else {
                    UV.y = uv.y; 
                }
                return UV;
            }
            
            
            // занять текстурой весь экран с обрезкой текстуры и сохранением aspect текстуры
            void main() 
            {
            float imageAspect = imageSize.x / imageSize.y;
            float screenAspect = screenSize.x / screenSize.y;
            vec2 UV;
            
            
                if (type == 0) UV = vUv;
                if (type == 1) UV = transformUVOuter(vUv,imageAspect,screenAspect);
                if (type == 2) UV = transformUVInner(vUv,imageAspect,screenAspect);
                if (type == 3) UV = transformUVInner((vUv / uvScale) + uvBias,imageAspect,screenAspect);
            
                if (UV.x < 0.0 || UV.y < 0.0 || UV.x > 1.0 || UV.y > 1.0)
                {discard;}
                    gl_FragColor = vec4(texture2D(tex,vec2(UV.x,1.0-UV.y)));
            }
             `
        );

        this.clearShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying highp vec2 vUv;
    uniform sampler2D uTexture;
    uniform float value;
    void main () {
        gl_FragColor = value * texture2D(uTexture, vUv);
    }
`
        );

        this.colorShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    uniform vec4 color;
    void main () {
        gl_FragColor = color;
    }
`
        );

        this.backgroundShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    uniform sampler2D uTexture;
    uniform float aspectRatio;
    #define SCALE 25.0
    void main () {
        vec2 uv = floor(vUv * SCALE * vec2(aspectRatio, 1.0));
        float v = mod(uv.x + uv.y, 2.0);
        v = v * 0.1 + 0.8;
        gl_FragColor = vec4(vec3(v), 1.0);
    }
`
        );

        this.displayShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    uniform sampler2D uTexture;
    void main () {
        vec3 C = texture2D(uTexture, vUv).rgb;
        float a = max(C.r, max(C.g, C.b));
        gl_FragColor = vec4(C, a);
    }
`
        );

        this.displayBloomShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    uniform sampler2D uTexture;
    uniform sampler2D uBloom;
    uniform sampler2D uDithering;
    uniform vec2 ditherScale;
    void main () {
        vec3 C = texture2D(uTexture, vUv).rgb;
        vec3 bloom = texture2D(uBloom, vUv).rgb;
        vec3 noise = texture2D(uDithering, vUv * ditherScale).rgb;
        noise = noise * 2.0 - 1.0;
        bloom += noise / 800.0;
        bloom = pow(bloom.rgb, vec3(1.0 / 2.2));
        C += bloom;
        float a = max(C.r, max(C.g, C.b));
        gl_FragColor = vec4(C, a);
    }
`
        );

        this.displayShadingShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uTexture;
    uniform vec2 texelSize;
    void main () {
        vec3 L = texture2D(uTexture, vL).rgb;
        vec3 R = texture2D(uTexture, vR).rgb;
        vec3 T = texture2D(uTexture, vT).rgb;
        vec3 B = texture2D(uTexture, vB).rgb;
        vec3 C = texture2D(uTexture, vUv).rgb;
        float dx = length(R) - length(L);
        float dy = length(T) - length(B);
        vec3 n = normalize(vec3(dx, dy, length(texelSize)));
        vec3 l = vec3(0.0, 0.0, 1.0);
        float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);
        C.rgb *= diffuse;
        float a = max(C.r, max(C.g, C.b));
        gl_FragColor = vec4(C, a);
    }
`
        );

        this.displayBloomShadingShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uTexture;
    uniform sampler2D uBloom;
    uniform sampler2D uDithering;
    uniform vec2 ditherScale;
    uniform vec2 texelSize;
    void main () {
        vec3 L = texture2D(uTexture, vL).rgb;
        vec3 R = texture2D(uTexture, vR).rgb;
        vec3 T = texture2D(uTexture, vT).rgb;
        vec3 B = texture2D(uTexture, vB).rgb;
        vec3 C = texture2D(uTexture, vUv).rgb;
        float dx = length(R) - length(L);
        float dy = length(T) - length(B);
        vec3 n = normalize(vec3(dx, dy, length(texelSize)));
        vec3 l = vec3(0.0, 0.0, 1.0);
        float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);
        C *= diffuse;
        vec3 bloom = texture2D(uBloom, vUv).rgb;
        vec3 noise = texture2D(uDithering, vUv * ditherScale).rgb;
        noise = noise * 2.0 - 1.0;
        bloom += noise / 800.0;
        bloom = pow(bloom.rgb, vec3(1.0 / 2.2));
        C += bloom;
        float a = max(C.r, max(C.g, C.b));
        gl_FragColor = vec4(C, a);
    }
`
        );

        this.bloomPrefilterShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying vec2 vUv;
    uniform sampler2D uTexture;
    uniform sampler2D vTexture;
    uniform vec3 curve;
    uniform float threshold;
    void main () {
        vec3 c = texture2D(uTexture, vUv).rgb;
        vec2 vel = abs(texture2D(vTexture, vUv).rg * 2.0 - 1.0);
        float vp = max(vel.r, vel.g);
        float br = max(c.r, max(c.g, c.b));
        float rq = clamp(br - curve.x, 0.0, curve.y);
        rq = curve.z * rq * rq;
        c *= max(rq, br - threshold) / max(br, 0.0001);
        c *= 0.5 + vp * 0.05 * step(0.5, vp);
        gl_FragColor = vec4(c, 1.0);
    }
`
        );

        this.bloomBlurShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uTexture;
    void main () {
        vec4 sum = vec4(0.0);
        sum += texture2D(uTexture, vL);
        sum += texture2D(uTexture, vR);
        sum += texture2D(uTexture, vT);
        sum += texture2D(uTexture, vB);
        sum *= 0.25;
        gl_FragColor = sum;
    }
`
        );

        this.bloomFinalShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uTexture;
    uniform float intensity;
    void main () {
        vec4 sum = vec4(0.0);
        sum += texture2D(uTexture, vL);
        sum += texture2D(uTexture, vR);
        sum += texture2D(uTexture, vT);
        sum += texture2D(uTexture, vB);
        sum *= 0.25;
        gl_FragColor = sum * intensity;
    }
`
        );

        this.splatShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    uniform sampler2D uTarget;
    uniform float aspectRatio;
    uniform vec3 color;
    uniform vec2 point;
    uniform float radius;
    void main () {
        vec2 p = vUv - point.xy;
        p.x *= aspectRatio;
        vec3 splat = exp(-dot(p, p) / radius) * color;
        vec3 base = texture2D(uTarget, vUv).xyz;
        gl_FragColor = vec4(base+splat,1.0);
    }
`
        );

        this.advectionManualFilteringShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    uniform sampler2D uVelocity;
    uniform sampler2D uSource;
    uniform vec2 texelSize;
    uniform vec2 dyeTexelSize;
    uniform float dt;
    uniform float dissipation;
    vec4 bilerp (sampler2D sam, vec2 uv, vec2 tsize) {
        vec2 st = uv / tsize - 0.5;
        vec2 iuv = floor(st);
        vec2 fuv = fract(st);
        vec4 a = texture2D(sam, (iuv + vec2(0.5, 0.5)) * tsize);
        vec4 b = texture2D(sam, (iuv + vec2(1.5, 0.5)) * tsize);
        vec4 c = texture2D(sam, (iuv + vec2(0.5, 1.5)) * tsize);
        vec4 d = texture2D(sam, (iuv + vec2(1.5, 1.5)) * tsize);
        return mix(mix(a, b, fuv.x), mix(c, d, fuv.x), fuv.y);
    }
    void main () {
        vec2 coord = vUv - dt * bilerp(uVelocity, vUv, texelSize).xy * texelSize;
        gl_FragColor = dissipation * bilerp(uSource, coord, dyeTexelSize);
        gl_FragColor.a = 1.0;
    }
`
        );

        this.advectionShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    uniform sampler2D uVelocity;
    uniform sampler2D uSource;
    uniform sampler2D u0;
    uniform vec2 texelSize;
    uniform float dt;
    uniform float dissipation;
    void main () {
        vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;
        gl_FragColor = dissipation * texture2D(uSource, coord) + (1.0 - dissipation) * texture2D(u0, coord);
        gl_FragColor.a = 1.0;
    }
`
        );

        this.divergenceShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uVelocity;
    void main () {
        float L = texture2D(uVelocity, vL).x;
        float R = texture2D(uVelocity, vR).x;
        float T = texture2D(uVelocity, vT).y;
        float B = texture2D(uVelocity, vB).y;
        vec2 C = texture2D(uVelocity, vUv).xy;
        if (vL.x < 0.0) { L = -C.x; }
        if (vR.x > 1.0) { R = -C.x; }
        if (vT.y > 1.0) { T = -C.y; }
        if (vB.y < 0.0) { B = -C.y; }
        float div = 0.5 * (R - L + T - B);
        gl_FragColor = vec4(div, 0.0, 0.0, 1.0);
    }
`
        );

        this.curlShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uVelocity;
    void main () {
        float L = texture2D(uVelocity, vL).y;
        float R = texture2D(uVelocity, vR).y;
        float T = texture2D(uVelocity, vT).x;
        float B = texture2D(uVelocity, vB).x;
        float vorticity = R - L - T + B;
        gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);
    }
`
        );

        this.vorticityShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision highp float;
    precision highp sampler2D;
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uVelocity;
    uniform sampler2D uCurl;
    uniform float curl;
    uniform float dt;
    void main () {
        float L = texture2D(uCurl, vL).x;
        float R = texture2D(uCurl, vR).x;
        float T = texture2D(uCurl, vT).x;
        float B = texture2D(uCurl, vB).x;
        float C = texture2D(uCurl, vUv).x;
        vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));
        force /= length(force) + 0.0001;
        force *= curl * C;
        force.y *= -1.0;
        vec2 vel = texture2D(uVelocity, vUv).xy;
        gl_FragColor = vec4(vel + force * dt, 0.0, 1.0);
    }
`
        );

        this.pressureShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uPressure;
    uniform sampler2D uDivergence;
    vec2 boundary (vec2 uv) {
        return uv;
        // uncomment if you use wrap or repeat texture mode
        // uv = min(max(uv, 0.0), 1.0);
        // return uv;
    }
    void main () {
        float L = texture2D(uPressure, boundary(vL)).x;
        float R = texture2D(uPressure, boundary(vR)).x;
        float T = texture2D(uPressure, boundary(vT)).x;
        float B = texture2D(uPressure, boundary(vB)).x;
        float C = texture2D(uPressure, vUv).x;
        float divergence = texture2D(uDivergence, vUv).x;
        float pressure = (L + R + B + T - divergence) * 0.25;
        gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);
    }
`
        );

        this.gradientSubtractShader = this.compileShader(
            gl.FRAGMENT_SHADER,
            `
    precision mediump float;
    precision mediump sampler2D;
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uPressure;
    uniform sampler2D uVelocity;
    vec2 boundary (vec2 uv) {
        return uv;
        // uv = min(max(uv, 0.0), 1.0);
        // return uv;
    }
    void main () {
        float L = texture2D(uPressure, boundary(vL)).x;
        float R = texture2D(uPressure, boundary(vR)).x;
        float T = texture2D(uPressure, boundary(vT)).x;
        float B = texture2D(uPressure, boundary(vB)).x;
        vec2 velocity = texture2D(uVelocity, vUv).xy;
        velocity.xy -= vec2(R - L, T - B);
        gl_FragColor = vec4(velocity, 0.0, 1.0);
    }
`
        );
    }

    loadDensity() {
        const {gl} = this;
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        gl.enable(gl.BLEND);
        this.fbo = this.createFBO(
            this.canvas.width,
            this.canvas.height,
            this.ext.formatRGBA.internalFormat,
            this.ext.formatRGBA.format,
            this.ext.halfFloatTexType,
            gl.LINEAR
        );

        this.drawTextureProgram.bind();
        gl.viewport(0, 0, this.dyeWidth, this.dyeHeight);
        gl.uniform1i(this.drawTextureProgram.uniforms.tex, this.crystalsLogo.attach(0));
        gl.uniform1i(this.drawTextureProgram.uniforms.type, 3);
        gl.uniform2fv(this.drawTextureProgram.uniforms.screenSize, [
            this.dyeWidth,
            this.dyeHeight
        ]);
        gl.uniform2fv(this.drawTextureProgram.uniforms.imageSize, [
            this.crystalsLogo.width,
            this.crystalsLogo.height
        ]);
        gl.uniform2fv(this.drawTextureProgram.uniforms.uvBias, [0.15, -0.12]);
        gl.uniform1f(this.drawTextureProgram.uniforms.uvScale, 0.8);

        this.blit(this.density.write.fbo);
        this.density.swap();

        gl.viewport(0, 0, this.canvas.width, this.canvas.height);
        gl.uniform1i(this.drawTextureProgram.uniforms.tex, this.crystalsLogo.attach(0));
        gl.uniform1i(this.drawTextureProgram.uniforms.type, 3);
        gl.uniform2fv(this.drawTextureProgram.uniforms.screenSize, [
            this.canvas.width,
            this.canvas.height
        ]);
        gl.uniform2fv(this.drawTextureProgram.uniforms.imageSize, [
            this.crystalsLogo.width,
            this.crystalsLogo.height
        ]);
        gl.uniform2fv(this.drawTextureProgram.uniforms.uvBias, [0.15, -0.12]);
        gl.uniform1f(this.drawTextureProgram.uniforms.uvScale, 0.8);

        this.blit(this.fbo.fbo);
        this.fbo.texture = this.fbo.fbo.texture;
        // This.fbo.attach(0);
        /*
         * Gl.activeTexture(gl.TEXTURE0);
         * gl.bindTexture(gl.TEXTURE_2D, this.fbo.texture);
         * gl.copyTexImage2D(
         * gl.TEXTURE_2D, // Target
         * 0, // Mip level, has to be zero for default framebuffer
         * gl.RGBA, // Pixel format, default framebuffer is RGBA if the context was created with alpha false you need to use RGB
         * 0,
         * 0, // X,y
         * this.canvas.width, // Width
         * this.canvas.height, // Height
         * 0 // Border, always 0
         *);
         */
    }

    initFramebuffers() {
        let {ext, gl} = this;
        let simRes = this.getResolution(this.config.SIM_RESOLUTION);
        let dyeRes = this.getResolution(this.config.DYE_RESOLUTION);

        this.simWidth = simRes.width;
        this.simHeight = simRes.height;
        this.dyeWidth = dyeRes.width;
        this.dyeHeight = dyeRes.height;

        const texType = ext.halfFloatTexType;
        const rgba = ext.formatRGBA;
        const rg = ext.formatRG;
        const r = ext.formatR;
        const filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST;

        if (this.density === null) {
            this.density = this.createDoubleFBO(
                this.dyeWidth,
                this.dyeHeight,
                rgba.internalFormat,
                rgba.format,
                texType,
                filtering
            );

            /*
             * This.density.read = this.evenstarLogo;
             * this.density = this.resizeDoubleFBO(
             * this.density,
             * this.dyeWidth,
             * this.dyeHeight,
             * rgba.internalFormat,
             * rgba.format,
             * texType,
             * filtering
             *);
             */

            this.loadDensity();
        } else {
            this.density = this.resizeDoubleFBO(
                this.density,
                this.dyeWidth,
                this.dyeHeight,
                rgba.internalFormat,
                rgba.format,
                texType,
                filtering
            );
        }
        if (this.velocity === null) {
            this.velocity = this.createDoubleFBO(
                this.simWidth,
                this.simHeight,
                rg.internalFormat,
                rg.format,
                texType,
                filtering
            );
        } else {
            this.velocity = this.resizeDoubleFBO(
                this.velocity,
                this.simWidth,
                this.simHeight,
                rg.internalFormat,
                rg.format,
                texType,
                filtering
            );
        }

        this.divergence = this.createFBO(
            this.simWidth,
            this.simHeight,
            r.internalFormat,
            r.format,
            texType,
            gl.LINEAR
        );
        this.curl = this.createFBO(
            this.simWidth,
            this.simHeight,
            r.internalFormat,
            r.format,
            texType,
            gl.LINEAR
        );
        this.pressure = this.createDoubleFBO(
            this.simWidth,
            this.simHeight,
            r.internalFormat,
            r.format,
            texType,
            gl.LINEAR
        );

        this.initBloomFramebuffers();
    }

    initBloomFramebuffers() {
        const {ext, gl} = this;
        let res = this.getResolution(this.config.BLOOM_RESOLUTION);

        const texType = ext.halfFloatTexType;
        const rgba = ext.formatRGBA;
        const filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.LINEAR;

        this.bloom = this.createFBO(
            res.width,
            res.height,
            rgba.internalFormat,
            rgba.format,
            texType,
            filtering
        );

        this.bloomFramebuffers.length = 0;
        for (let i = 0; i < this.config.BLOOM_ITERATIONS; i++) {
            let width = res.width >> (i + 1);
            let height = res.height >> (i + 1);

            if (width < 2 || height < 2) {
                break;
            }

            let fbo = this.createFBO(
                width,
                height,
                rgba.internalFormat,
                rgba.format,
                texType,
                filtering
            );
            this.bloomFramebuffers.push(fbo);
        }
    }

    createFBO(w, h, internalFormat, format, type, param) {
        const {gl} = this;
        gl.activeTexture(gl.TEXTURE0);
        let texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, param);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, param);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, w, h, 0, format, type, null);

        let fbo = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
        gl.framebufferTexture2D(
            gl.FRAMEBUFFER,
            gl.COLOR_ATTACHMENT0,
            gl.TEXTURE_2D,
            texture,
            0
        );
        gl.viewport(0, 0, w, h);
        gl.clear(gl.COLOR_BUFFER_BIT);

        return {
            texture,
            fbo,
            width: w,
            height: h,
            attach(id) {
                gl.activeTexture(gl.TEXTURE0 + id);
                gl.bindTexture(gl.TEXTURE_2D, texture);

                return id;
            }
        };
    }

    createDoubleFBO(w, h, internalFormat, format, type, param) {
        let fbo1 = this.createFBO(w, h, internalFormat, format, type, param);
        let fbo2 = this.createFBO(w, h, internalFormat, format, type, param);

        return {
            get read() {
                return fbo1;
            },
            set read(value) {
                fbo1 = value;
            },
            get write() {
                return fbo2;
            },
            set write(value) {
                fbo2 = value;
            },
            swap() {
                let temp = fbo1;
                fbo1 = fbo2;
                fbo2 = temp;
            }
        };
    }

    resizeFBO(target, w, h, internalFormat, format, type, param) {
        const {gl} = this;
        let newFBO = this.createFBO(w, h, internalFormat, format, type, param);
        this.clearProgram.bind();
        gl.uniform1i(this.clearProgram.uniforms.uTexture, target.attach(0));
        gl.uniform1f(this.clearProgram.uniforms.value, 1);
        this.blit(newFBO.fbo);

        return newFBO;
    }

    resizeDoubleFBO(target, w, h, internalFormat, format, type, param) {
        target.read = this.resizeFBO(
            target.read,
            w,
            h,
            internalFormat,
            format,
            type,
            param
        );
        target.write = this.createFBO(w, h, internalFormat, format, type, param);

        return target;
    }

    createTextureAsync(url) {
        const {gl} = this;
        let texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texImage2D(
            gl.TEXTURE_2D,
            0,
            gl.RGBA,
            1,
            1,
            0,
            gl.RGBA,
            gl.UNSIGNED_SHORT_5_5_5_1,
            new Uint8Array([255, 255, 255])
        );

        let obj = {
            texture,
            width: 1,
            height: 1,
            attach(id) {
                gl.activeTexture(gl.TEXTURE0 + id);
                gl.bindTexture(gl.TEXTURE_2D, texture);

                return id;
            }
        };

        let image = new Image();
        image.onload = () => {
            obj.width = image.width;
            obj.height = image.height;
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texImage2D(
                gl.TEXTURE_2D,
                0,
                gl.RGBA,
                gl.RGBA,
                gl.UNSIGNED_SHORT_5_5_5_1,
                image
            );
        };
        image.src = url;

        return obj;
    }

    restoreSettings() {
        this.config.DENSITY_DISSIPATION = this.lastDensityDiss;
        this.config.VELOCITY_DISSIPATION = this.lastVelocityDiss;
        this.config.PRESSURE_DISSIPATION = this.lastPressureDiss;
        this.isPointerUpdating = false;
    }

    resetField() {
        this.config.DENSITY_DISSIPATION = 0.0;
        this.config.VELOCITY_DISSIPATION = 0.0;
        this.config.PRESSURE_DISSIPATION = 0.5;
        this.config.PAUSED = true;
    }

    update(time = 0) {
        if (this.enabled) {
            this.lastRedrawTime = time;

            if (this.isPointerUpdating) {
                if (time - this.lastPointerTime >= 5000) {
                    this.resetField();
                    this.step(0.5);
                    this.step(0.5);
                    this.restoreSettings();
                } else if (time - this.lastPointerTime >= 3000) {
                    this.config.DENSITY_DISSIPATION -= 0.01;
                    this.config.VELOCITY_DISSIPATION -= 0.01;
                    this.config.PRESSURE_DISSIPATION -= 0.0005;

                    this.config.DENSITY_DISSIPATION = Math.max(
                        Math.min(this.config.DENSITY_DISSIPATION, 1.0),
                        0.0
                    );

                    this.config.VELOCITY_DISSIPATION = Math.max(
                        Math.min(this.config.VELOCITY_DISSIPATION, 1.0),
                        0.0
                    );

                    this.config.PRESSURE_DISSIPATION = Math.max(
                        Math.min(this.config.PRESSURE_DISSIPATION, 1.0),
                        0.0
                    );
                }
            }

            //this.resizeCanvas();
            this.input();
            if (!this.config.PAUSED) {
                this.step(0.016); // 0.016 0.0167
            }
            this.render(null);

            this.animationFrame = requestAnimationFrame((time) => this.update(time));
        }
    }

    isInViewport = () => {
        let bounding = this.canvas.getBoundingClientRect();
        let x = bounding.left;
        let y = bounding.top;
        let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        let w = this.canvas.clientWidth;
        let h = this.canvas.clientHeight;
        return (
            (y < hw &&
                y + h > 0) &&
            (x < ww &&
                x + w > 0)
        );
    };

    input() {
        /*
         * This.splat(
         * this.canvas.width / 2,
         * 0,
         * 0,
         * 30,
         * this.generateRGBColor(0.05, 0.0, 0.0)
         * );
         * Splat(canvas.width / 2, 0, 0, 30, generateRGBColor(1.0,0.0,0.0));
         * splat(canvas.width / 2, canvas.height, 0, -3000, generateRGBColor(0.0,20.0,0.0));
         * splat(0, canvas.height / 2, 3000, 0, generateRGBColor(0.0,0.0,20.0));
         * splat(canvas.width , canvas.height / 2, -3000, 0, generateRGBColor(10.0,10.0,0.0));
         */

        /*
         *This.splat(
         * this.canvas.width / 2,
         * 0,
         * 0,
         * 40,
         * this.generateRGBColor(0.02, 0.0, 0.0)
         * );
         * this.splat(
         * this.canvas.width / 2,
         * this.canvas.height,
         * 0,
         * -40,
         * this.generateRGBColor(0.0, 0.02, 0.0)
         * );
         * this.splat(
         * this.canvas.width,
         * this.canvas.height / 2,
         * -60,
         * 0,
         * this.generateRGBColor(0.0, 0.0, 0.02)
         * );
         * this.splat(
         * 0,
         * this.canvas.height / 2,
         * 60,
         * 0,
         * this.generateRGBColor(0.0, 0.02, 0.02)
         *);
         */

        if (this.splatStack.length > 0) {
            this.multipleSplats(this.splatStack.pop());
        }

        for (let i = 0; i < this.pointers.length; i++) {
            const p = this.pointers[i];
            if (p.moved) {
                this.splat(p.x, p.y, p.dx, p.dy, p.color);
                p.moved = false;
            }
        }

        if (!this.config.COLORFUL) {
            return;
        }

        if (this.lastColorChangeTime + 100 < Date.now()) {
            this.lastColorChangeTime = Date.now();
            for (let i = 0; i < this.pointers.length; i++) {
                const p = this.pointers[i];
                p.color = this.generateRGBColor(0.0, 0.0, 0.0);
            }
        }
    }

    step(dt) {
        if (!this.enabled) {
            return false;
        }

        const {gl} = this;
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        gl.enable(gl.BLEND);
        gl.disable(gl.DEPTH_TEST);
        gl.viewport(0, 0, this.simWidth, this.simHeight);
        this.curlProgram.bind();
        gl.uniform2f(
            this.curlProgram.uniforms.texelSize,
            1.0 / this.simWidth,
            1.0 / this.simHeight
        );
        gl.uniform1i(this.curlProgram.uniforms.uVelocity, this.velocity.read.attach(0));
        this.blit(this.curl.fbo);

        this.vorticityProgram.bind();

        gl.uniform2f(
            this.vorticityProgram.uniforms.texelSize,
            1.0 / this.simWidth,
            1.0 / this.simHeight
        );
        gl.uniform1i(
            this.vorticityProgram.uniforms.uVelocity,
            this.velocity.read.attach(0)
        );
        gl.uniform1i(this.vorticityProgram.uniforms.uCurl, this.curl.attach(1));
        gl.uniform1f(this.vorticityProgram.uniforms.curl, this.config.CURL);
        gl.uniform1f(this.vorticityProgram.uniforms.dt, dt);
        this.blit(this.velocity.write.fbo);
        this.velocity.swap();

        this.divergenceProgram.bind();
        gl.uniform2f(
            this.divergenceProgram.uniforms.texelSize,
            1.0 / this.simWidth,
            1.0 / this.simHeight
        );
        gl.uniform1i(
            this.divergenceProgram.uniforms.uVelocity,
            this.velocity.read.attach(0)
        );
        this.blit(this.divergence.fbo);

        this.clearProgram.bind();
        gl.uniform1i(this.clearProgram.uniforms.uTexture, this.pressure.read.attach(0));
        gl.uniform1f(this.clearProgram.uniforms.value, this.config.PRESSURE_DISSIPATION);
        this.blit(this.pressure.write.fbo);
        this.pressure.swap();

        this.pressureProgram.bind();
        gl.uniform2f(
            this.pressureProgram.uniforms.texelSize,
            1.0 / this.simWidth,
            1.0 / this.simHeight
        );
        gl.uniform1i(
            this.pressureProgram.uniforms.uDivergence,
            this.divergence.attach(0)
        );
        for (let i = 0; i < this.config.PRESSURE_ITERATIONS; i++) {
            gl.uniform1i(
                this.pressureProgram.uniforms.uPressure,
                this.pressure.read.attach(1)
            );
            this.blit(this.pressure.write.fbo);
            this.pressure.swap();
        }

        this.gradienSubtractProgram.bind();
        gl.uniform2f(
            this.gradienSubtractProgram.uniforms.texelSize,
            1.0 / this.simWidth,
            1.0 / this.simHeight
        );
        gl.uniform1i(
            this.gradienSubtractProgram.uniforms.uPressure,
            this.pressure.read.attach(0)
        );
        gl.uniform1i(
            this.gradienSubtractProgram.uniforms.uVelocity,
            this.velocity.read.attach(1)
        );
        this.blit(this.velocity.write.fbo);
        this.velocity.swap();

        this.advectionProgram.bind();
        gl.uniform2f(
            this.advectionProgram.uniforms.texelSize,
            1.0 / this.simWidth,
            1.0 / this.simHeight
        );
        if (!this.ext.supportLinearFiltering) {
            gl.uniform2f(
                this.advectionProgram.uniforms.dyeTexelSize,
                1.0 / this.simWidth,
                1.0 / this.simHeight
            );
        }
        let velocityId = this.velocity.read.attach(0);
        gl.uniform1i(this.advectionProgram.uniforms.uVelocity, velocityId);
        gl.uniform1i(this.advectionProgram.uniforms.uSource, velocityId);
        // Gl.uniform1i(this.advectionProgram.uniforms.u0, velocityId);
        gl.uniform1f(this.advectionProgram.uniforms.dt, dt);
        gl.uniform1f(
            this.advectionProgram.uniforms.dissipation,
            this.config.VELOCITY_DISSIPATION
        );
        this.blit(this.velocity.write.fbo);
        this.velocity.swap();

        gl.viewport(0, 0, this.dyeWidth, this.dyeHeight);

        if (!this.ext.supportLinearFiltering) {
            gl.uniform2f(
                this.advectionProgram.uniforms.dyeTexelSize,
                1.0 / this.dyeWidth,
                1.0 / this.dyeHeight
            );
        }
        gl.uniform1i(
            this.advectionProgram.uniforms.uVelocity,
            this.velocity.read.attach(0)
        );
        gl.uniform1i(this.advectionProgram.uniforms.uSource, this.density.read.attach(1));
        gl.uniform1i(this.advectionProgram.uniforms.u0, this.fbo.attach(2));
        gl.uniform1f(
            this.advectionProgram.uniforms.dissipation,
            this.config.DENSITY_DISSIPATION
        );
        this.blit(this.density.write.fbo);
        this.density.swap();
    }

    render(target) {
        if (!this.enabled) {
            return false;
        }

        const {gl, dyeWidth, dyeHeight} = this;
        if (this.config.BLOOM) {
            this.applyBloom(this.density.read, this.velocity.read, this.bloom);
        }

        if (target === null || !this.config.TRANSPARENT) {
            gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
            gl.enable(gl.BLEND);
        } else {
            gl.disable(gl.BLEND);
        }

        let width = target === null ? gl.drawingBufferWidth : dyeWidth;
        let height = target === null ? gl.drawingBufferHeight : dyeHeight;

        gl.viewport(0, 0, width, height);

        if (!this.config.TRANSPARENT) {
            this.colorProgram.bind();
            let bc = this.config.BACK_COLOR;
            gl.uniform4f(
                this.colorProgram.uniforms.color,
                bc.r / 255,
                bc.g / 255,
                bc.b / 255,
                1
            );
            this.blit(target);
        }

        this.drawTextureProgram.bind();
        gl.viewport(0, 0, this.canvas.width, this.canvas.height);
        gl.uniform1i(this.drawTextureProgram.uniforms.tex, this.backgroundLogo.attach(0));
        gl.uniform1i(this.drawTextureProgram.uniforms.type, 3);
        gl.uniform2fv(this.drawTextureProgram.uniforms.screenSize, [
            this.canvas.width,
            this.canvas.height
        ]);
        gl.uniform2fv(this.drawTextureProgram.uniforms.imageSize, [
            this.backgroundLogo.width,
            this.backgroundLogo.height
        ]);
        gl.uniform2fv(this.drawTextureProgram.uniforms.uvBias, [0.15, -0.12]);
        gl.uniform1f(this.drawTextureProgram.uniforms.uvScale, 0.8);
        this.blit(target);

        if (target === null && this.config.TRANSPARENT) {
            this.backgroundProgram.bind();
            gl.uniform1f(
                this.backgroundProgram.uniforms.aspectRatio,
                this.canvas.width / this.canvas.height
            );
            this.blit(null);
        }

        if (this.config.SHADING) {
            let program = this.config.BLOOM
                ? this.displayBloomShadingProgram
                : this.displayShadingProgram;
            program.bind();
            gl.uniform2f(program.uniforms.texelSize, 1.0 / width, 1.0 / height);
            gl.uniform1i(program.uniforms.uTexture, this.density.read.attach(0));
            if (this.config.BLOOM) {
                gl.uniform1i(program.uniforms.uBloom, this.bloom.attach(1));
                gl.uniform1i(
                    program.uniforms.uDithering,
                    this.ditheringTexture.attach(2)
                );
                let scale = this.getTextureScale(this.ditheringTexture, width, height);
                gl.uniform2f(program.uniforms.ditherScale, scale.x, scale.y);
            }
        } else {
            let program = this.config.BLOOM
                ? this.displayBloomProgram
                : this.displayProgram;
            program.bind();
            gl.uniform1i(program.uniforms.uTexture, this.density.read.attach(0));
            if (this.config.BLOOM) {
                gl.uniform1i(program.uniforms.uBloom, this.bloom.attach(1));
                gl.uniform1i(
                    program.uniforms.uDithering,
                    this.ditheringTexture.attach(2)
                );
                let scale = this.getTextureScale(this.ditheringTexture, width, height);
                gl.uniform2f(program.uniforms.ditherScale, scale.x, scale.y);
            }
        }

        this.blit(target);
    }

    applyBloom(source, velocitySource, destination) {
        const {gl} = this;
        if (this.bloomFramebuffers.length < 2) {
            return;
        }

        let last = destination;

        gl.disable(gl.BLEND);
        this.bloomPrefilterProgram.bind();
        let knee = this.config.BLOOM_THRESHOLD * this.config.BLOOM_SOFT_KNEE + 0.0001;
        let curve0 = this.config.BLOOM_THRESHOLD - knee;
        let curve1 = knee * 2;
        let curve2 = 0.25 / knee;
        gl.uniform3f(this.bloomPrefilterProgram.uniforms.curve, curve0, curve1, curve2);
        gl.uniform1f(
            this.bloomPrefilterProgram.uniforms.threshold,
            this.config.BLOOM_THRESHOLD
        );
        gl.uniform1i(this.bloomPrefilterProgram.uniforms.uTexture, source.attach(0));
        gl.uniform1i(this.bloomPrefilterProgram.uniforms.vTexture, velocitySource.attach(1));
        gl.viewport(0, 0, last.width, last.height);
        this.blit(last.fbo);

        this.bloomBlurProgram.bind();
        for (let i = 0; i < this.bloomFramebuffers.length; i++) {
            let dest = this.bloomFramebuffers[i];
            gl.uniform2f(
                this.bloomBlurProgram.uniforms.texelSize,
                1.0 / last.width,
                1.0 / last.height
            );
            gl.uniform1i(this.bloomBlurProgram.uniforms.uTexture, last.attach(0));
            gl.viewport(0, 0, dest.width, dest.height);
            this.blit(dest.fbo);
            last = dest;
        }

        gl.blendFunc(gl.ONE, gl.ONE);
        gl.enable(gl.BLEND);

        for (let i = this.bloomFramebuffers.length - 2; i >= 0; i--) {
            let baseTex = this.bloomFramebuffers[i];
            gl.uniform2f(
                this.bloomBlurProgram.uniforms.texelSize,
                1.0 / last.width,
                1.0 / last.height
            );
            gl.uniform1i(this.bloomBlurProgram.uniforms.uTexture, last.attach(0));
            gl.viewport(0, 0, baseTex.width, baseTex.height);
            this.blit(baseTex.fbo);
            last = baseTex;
        }

        gl.disable(gl.BLEND);
        this.bloomFinalProgram.bind();
        gl.uniform2f(
            this.bloomFinalProgram.uniforms.texelSize,
            1.0 / last.width,
            1.0 / last.height
        );
        gl.uniform1i(this.bloomFinalProgram.uniforms.uTexture, last.attach(0));
        gl.uniform1f(
            this.bloomFinalProgram.uniforms.intensity,
            this.config.BLOOM_INTENSITY
        );
        gl.viewport(0, 0, destination.width, destination.height);
        this.blit(destination.fbo);
    }

    splat(x, y, dx, dy, color) {
        const {gl} = this;
        gl.viewport(0, 0, this.simWidth, this.simHeight);
        this.splatProgram.bind();
        gl.uniform1i(this.splatProgram.uniforms.uTarget, this.velocity.read.attach(0));
        gl.uniform1f(
            this.splatProgram.uniforms.aspectRatio,
            this.canvas.width / this.canvas.height
        );
        gl.uniform2f(
            this.splatProgram.uniforms.point,
            x / this.canvas.width,
            1.0 - y / this.canvas.height
        );
        gl.uniform3f(this.splatProgram.uniforms.color, dx, -dy, 1.0);
        gl.uniform1f(this.splatProgram.uniforms.radius, this.config.SPLAT_RADIUS / 100.0);
        this.blit(this.velocity.write.fbo);
        this.velocity.swap();

        gl.viewport(0, 0, this.dyeWidth, this.dyeHeight);
        gl.uniform1i(this.splatProgram.uniforms.uTarget, this.density.read.attach(0));
        gl.uniform3f(this.splatProgram.uniforms.color, 0, 0, 0);
        this.blit(this.density.write.fbo);
        this.density.swap();
    }

    multipleSplats(amount) {
        for (let i = 0; i < amount; i++) {
            const color = this.generateRandomColor();
            color.r *= 10.0;
            color.g *= 10.0;
            color.b *= 10.0;
            const x = this.canvas.width * Math.random();
            const y = this.canvas.height * Math.random();
            const dx = 1000 * (Math.random() - 0.5);
            const dy = 1000 * (Math.random() - 0.5);
            this.splat(x, y, dx, dy, color);
        }
    }

    resizeCanvas() {
        if (this.enabled === false) {
            return false;
        }

        if (
            this.canvas.width !== window.innerWidth ||
            this.canvas.height !== window.innerHeight
        ) {
            this.config.PAUSED = true;
            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight;
            if (Math.max(this.canvas.width, this.canvas.height) > 800) {
                this.config.SIM_RESOLUTION = 512;
                this.config.DYE_RESOLUTION = 1024;
            } else if (Math.max(this.canvas.width, this.canvas.height) > 400) {
                this.config.SIM_RESOLUTION = 256;
                this.config.DYE_RESOLUTION = 512;
            } else {
                this.config.SIM_RESOLUTION = 256;
                this.config.DYE_RESOLUTION = 512;
            }
            this.density = null;
            this.initFramebuffers();
        }
    }

    generateRandomColor() {
        let c = this.HSVtoRGB(Math.random(), 1.0, 1.0);
        c.r *= 0.15;
        c.g *= 0.15;
        c.b *= 0.15;

        return c;
    }

    generateHSVColor(h = 1.0, s = 1.0, v = 1.0) {
        let c = this.HSVtoRGB(h, s, v);
        /*
         *C.r *= 1.0;
         * c.g *= 1.0;
         * c.b *= 1.0;
         */

        return c;
    }

    generateRGBColor(r = 1.0, g = 1.0, b = 1.0) {
        let c = {};
        // = HSVtoRGB(h, s, v);
        c.r = r;
        c.g = g;
        c.b = b;

        return c;
    }

    HSVtoRGB(h, s, v) {
        let r, g, b, i, f, p, q, t;
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);

        switch (i % 6) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            case 5:
                r = v;
                g = p;
                b = q;
                break;
            default:
                break;
        }

        return {
            r,
            g,
            b
        };
    }

    getResolution(resolution) {
        const {gl} = this;
        let aspectRatio = gl.drawingBufferWidth / gl.drawingBufferHeight;
        if (aspectRatio < 1) {
            aspectRatio = 1.0 / aspectRatio;
        }

        let max = Math.round(resolution * aspectRatio);
        let min = Math.round(resolution);

        if (gl.drawingBufferWidth > gl.drawingBufferHeight) {
            return {width: max, height: min};
        }

        return {width: min, height: max};
    }

    getTextureScale(texture, width, height) {
        return {
            x: width / texture.width,
            y: height / texture.height
        };
    }

    removeMouse() {
        let mouseNode = document.body.querySelector('.hero-title__mouse');
        if (mouseNode && mouseNode.parentNode) {
            mouseNode.parentNode.removeChild(mouseNode);
        }
    }

    addEvents() {
        this.canvas.addEventListener('mousemove', (e) => {
            this.pointers[0].moved = this.pointers[0].down;
            this.pointers[0].dx = (e.offsetX - this.pointers[0].x) * 5.0;
            this.pointers[0].dy = (e.offsetY - this.pointers[0].y) * 5.0;
            this.pointers[0].x = e.offsetX;
            this.pointers[0].y = e.offsetY;
        });

        this.canvas.addEventListener(
            'touchmove',
            (e) => {
                e.preventDefault();
                const touches = e.targetTouches;
                for (let i = 0; i < touches.length; i++) {
                    let pointer = this.pointers[i];
                    pointer.moved = pointer.down;
                    pointer.dx = (touches[i].pageX - pointer.x) * 8.0;
                    pointer.dy = (touches[i].pageY - pointer.y) * 8.0;
                    pointer.x = touches[i].pageX;
                    pointer.y = touches[i].pageY;
                }
            },
            false
        );

        this.canvas.addEventListener('mousemove', () => {
            this.config.PAUSED = false;
            this.removeMouse();
            this.restoreSettings();
            document.body.classList.add('unselectable');
            this.pointers[0].down = true;
            this.pointers[0].color = this.generateRGBColor(0.0, 0.0, 0.0);

            this.isPointerUpdating = true;
            this.lastPointerTime = this.lastRedrawTime;
        });

        this.canvas.addEventListener('touchmove', (e) => {
            this.config.PAUSED = false;
            e.preventDefault();
            this.restoreSettings();
            document.body.classList.add('unselectable');
            const touches = e.targetTouches;
            for (let i = 0; i < touches.length; i++) {
                if (i >= this.pointers.length) {
                    this.pointers.push(new pointerPrototype());
                }

                this.pointers[i].id = touches[i].identifier;
                this.pointers[i].down = true;
                this.pointers[i].x = touches[i].pageX;
                this.pointers[i].y = touches[i].pageY;
                this.pointers[i].color = this.generateRGBColor(0.0, 0.0, 0.0);
            }

            this.isPointerUpdating = true;
            this.lastPointerTime = this.lastRedrawTime;
        });

        window.addEventListener('mouseup', () => {
            this.pointers[0].down = false;
            this.isPointerUpdating = true;
            this.lastPointerTime = this.lastRedrawTime;
            document.body.classList.remove('unselectable');
            /*
             *ClearTimeout(this.timer);
             * this.timer = setTimeout(() => {
             * this.loadDensity();
             *}, 2000);
             */
        });

        window.addEventListener('touchend', (e) => {
            document.body.classList.remove('unselectable');
            this.isPointerUpdating = true;
            this.lastPointerTime = this.lastRedrawTime;
            const touches = e.changedTouches;
            for (let i = 0; i < touches.length; i++) {
                for (let j = 0; j < this.pointers.length; j++) {
                    if (touches[i].identifier === this.pointers[j].id) {
                        this.pointers[j].down = false;
                    }
                }
            }
        });

        window.addEventListener('keydown', (e) => {
            if (e.code === 'KeyP') {
                this.config.PAUSED = !this.config.PAUSED;
            }
            if (e.key === ' ') {
                this.splatStack.push(this.parseInt(Math.random() * 20) + 5);
            }
        });

        this.canvas.addEventListener('webglcontextlost', (event) => {
            event.preventDefault();
            this.enabled = false;
            this.webGLBroken = true;
            window.removeEventListener('resize', onResize);
            cancelAnimationFrame(this.animationFrame);
            this.canvas.parentNode.removeChild(this.canvas);
            // Reflect.deleteProperty(this, 'canvas');
            // Reflect.deleteProperty(this, 'gl');
        });

        this.canvas.addEventListener('webglcontextrestored', () => {
            document.location.reload(true);
        }, false);

        window.addEventListener('scroll', () => {
            if (!this.isInViewport() && !this.isPointerUpdating) {
                this.config.PAUSED = true;
            }
        });

        let onResize = () => {
            requestAnimationFrame(() => {
                this.resizeCanvas();
            });
        };

        window.addEventListener('resize', onResize);
        this.resizeCanvas();
    }
}

appData.init = () => {
    appData.canvas = document.createElement('canvas');
    appData.solver = new NavierStokesSolver(appData.canvas);
};
