import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import { Feature, contentPropTypes } from '../Feature';
import { Platforms } from '../Platforms';

export const HowWeDo = ({ content, platforms }) => (
    <div className="how-we-do">
        <Platforms platforms={platforms} />
        <Feature appearDelay={600} {...content} />
    </div>
);

HowWeDo.propTypes = {
    content: shape(contentPropTypes).isRequired,
    platforms: arrayOf(string).isRequired,
};
