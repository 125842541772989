import React from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './MobileCarousel.scss';

class MobileCarousel extends React.Component {
  render = () => {
    return (
      <CarouselProvider
        visibleSlides={1}
        totalSlides={this.props.children.length}
        step={1}
        naturalSlideWidth={260}
        naturalSlideHeight={470}
        infinite
        interval={5000}
        isPlaying
        touchEnabled
        dragEnabled
      >
        <Slider>
          {this.props.children.map((slide, index) => (
            <Slide
              key={index}
              index={index}
            >
              {slide}
            </Slide>
          ))}
        </Slider>
        <DotGroup/>
      </CarouselProvider>
    )
  }
}

export default MobileCarousel;
