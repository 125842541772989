import React from 'react';
import PropTypes from 'prop-types';

export class Marquee extends React.Component {

  state = {
    isDrag: false,
    mouse: {
      x: 0,
      y: 0,
      currentX: 0,
      currentY: 0
    },
    offsetX: 0
  };

  componentDidMount() {
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  renderMarquee = (repeat, duration, children, style) => {
    let result = [];
    for (let i = 0; i < repeat; i++) {
      result.push(
        <span
          className="marquee__text"
          style={{ animationDuration: `${duration}s`, ...style }}
          key={i}
        >&nbsp;{children}</span>
      );
    }

    return result;
  };

  onMouseDown = (e) => {
    this.setState({
      mouse: {
        x: e.pageX,
        y: e.pageY,
        currentX: e.pageX,
        currentY: e.pageY,
      },
      isDrag: true
    });
  };

  onMouseMove = (e) => {
    if (this.state.isDrag) {
      this.setState({
        mouse: {
          ...this.state.mouse,
          currentX: e.pageX,
          currentY: e.pageY,
        }
      });
    }
  };

  onMouseUp = () => {
    if (this.state.isDrag) {
      this.setState({
        isDrag: false
      });
    }
  };

  calculateMarqueeStyle = () => {
    if (!this.state.isDrag) {
      return null;
    }

    return {
      animationPlayState: "paused"
    };
  };

  calculateMainStyle = () => {
    if (!this.state.isDrag) {
      return null;
    }

    let offset = ((this.state.mouse.currentX - this.state.mouse.x) / window.innerWidth) * 100;

    return {
      transform: `translateX(${offset}%)`
    };
  };

  render() {
    const { children, repeat, duration } = this.props;

    const childStyle = this.calculateMarqueeStyle();
    const mainStyle = this.calculateMainStyle();

    return (
      <div className="marquee">
        <div className={`marquee__content ${(this.state.isDrag) ? 'dragged' : ''}`}
             onMouseDown={this.onMouseDown}
             style={mainStyle}
        >
          {this.renderMarquee(repeat, duration, children, childStyle)}
        </div>
      </div>
    );
  }
}

Marquee.propTypes = {
  children: PropTypes.node.isRequired,
  repeat: PropTypes.number,
  duration: PropTypes.number,
};

Marquee.defaultProps = {
  repeat: 2,
  duration: 45,
};
