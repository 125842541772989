import logoSrc from '../../../../assets/evenstar-logo.svg';

let assets = [];

// const ceilPowerOfTwo = (value) => {
//     return Math.pow(2, Math.ceil(Math.log(value) / Math.LN2));
// };

const SIZE = {
    x: 102,//128,//102
    y: 86//108//86
};

export const loadLogo = () => {
    return new Promise((resolve) => {
        let image = new Image();
        image.onload = () => {
            image.width = SIZE.x;//ceilPowerOfTwo(image.width);
            image.height = SIZE.y;//ceilPowerOfTwo(image.height);

            assets.push(image);
            resolve();
        };

        image.src = logoSrc;
    });
};

const compileSource = (gl, type, source) => {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        throw gl.getShaderInfoLog(shader);
    }

    return shader;
};

class GLProgram {
    constructor(gl, vertexShader, fragmentShader) {
        this.gl = gl;
        this.uniforms = {};
        this.program = gl.createProgram();

        gl.attachShader(this.program, compileSource(gl, gl.VERTEX_SHADER, vertexShader));
        gl.attachShader(this.program, compileSource(gl, gl.FRAGMENT_SHADER, fragmentShader));
        gl.linkProgram(this.program);

        if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
            throw gl.getProgramInfoLog(this.program);
        }

        const uniformCount = gl.getProgramParameter(this.program, gl.ACTIVE_UNIFORMS);
        for (let i = 0; i < uniformCount; i++) {
            const uniformName = gl.getActiveUniform(this.program, i).name;
            this.uniforms[uniformName] = gl.getUniformLocation(this.program, uniformName);
        }
    }

    bind() {
        this.gl.useProgram(this.program);
    }
}

const planeCreator = ((gl) => {
    gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
    gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]),
        gl.STATIC_DRAW
    );
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, gl.createBuffer());
    gl.bufferData(
        gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array([0, 1, 2, 0, 2, 3]),
        gl.STATIC_DRAW
    );
    gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(0);

    return (destination) => {
        gl.bindFramebuffer(gl.FRAMEBUFFER, destination);
        gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
    };
});

const textureCreator = (gl, image) => {
    let texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    //gl.generateMipmap(gl.TEXTURE_2D);

    let ext = (
        gl.getExtension('EXT_texture_filter_anisotropic') ||
        gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
        gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic')
    );
    if (ext){
        let max = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
        gl.texParameterf(gl.TEXTURE_2D, ext.TEXTURE_MAX_ANISOTROPY_EXT, max);
    }

    gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        image
    );

    return {
        texture,
        width: image.width,
        height: image.height,
        attach() {
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, texture);
        }
    };
};

let calculateCoords = (time = 0.0, period, number) => {
    const coords1 = [
        {x: 0.255, y: 0.705},
        {x: 0.267, y: 0.7},
        {x: 0.28, y: 0.695},
        {x: 0.3, y: 0.69},
        {x: 0.32, y: 0.687},
        {x: 0.34, y: 0.687},
        {x: 0.36, y: 0.69},
        {x: 0.38, y: 0.695},
        {x: 0.4, y: 0.703},
        {x: 0.42, y: 0.715},
        {x: 0.44, y: 0.73},
        {x: 0.46, y: 0.75},
        {x: 0.48, y: 0.79},
        {x: 0.5, y: 0.82},
        {x: 0.55, y: 0.86}
    ];
    const coords2 = [
        {x: 0.42, y: 0.62},
        {x: 0.4, y: 0.625},
        {x: 0.38, y: 0.635},
        {x: 0.36, y: 0.65},
        {x: 0.338, y: 0.685},
        {x: 0.338, y: 0.715},
        {x: 0.36, y: 0.755},
        {x: 0.38, y: 0.77},
        {x: 0.4, y: 0.78},
        {x: 0.42, y: 0.78},
        {x: 0.44, y: 0.775},
        {x: 0.46, y: 0.771},
        {x: 0.48, y: 0.79},
        {x: 0.5, y: 0.82},
        {x: 0.55, y: 0.86}
    ];
    let index = Math.floor(((time % period) * number) / period);
    // Index = number - index - 1;
    const kMix =
        ((time % period) * number) / period -
        Math.floor(((time % period) * number) / period);
    const center1 = {
        x: coords1[index + 0].x * (1.0 - kMix) + coords1[index + 1].x * kMix,
        y: coords1[index + 0].y * (1.0 - kMix) + coords1[index + 1].y * kMix
    };
    const center2 = {
        x: coords2[index + 0].x * (1.0 - kMix) + coords2[index + 1].x * kMix,
        y: coords2[index + 0].y * (1.0 - kMix) + coords2[index + 1].y * kMix
    };

    return {center1, center2};
};

const baseVertex = `
    precision highp float;
    
    attribute vec2 aPosition;
    varying vec2 vUv;
    
    void main () {
        vUv = aPosition * 0.5 + 0.5;
        vUv.y = 1.0 - vUv.y;
        gl_Position = vec4(aPosition, 0.0, 1.0);
    }
`;

const baseFragment = `
    precision highp float;
    precision highp sampler2D;
    
    varying highp vec2 vUv;
    uniform sampler2D uTexture;
    
    uniform vec2 resolution;
    uniform float time;
    
    uniform vec2 center1;
    uniform vec2 center2;
    
    void main () {
        float radius1 = 0.08;
        float radius2 = 0.09;

        vec4 pixel = texture2D(uTexture, vUv);
        
        vec2 uv1 = vec2(center1.x, 1.012 - center1.y) * 2.0 - 0.25;
        uv1.x -= 0.25;
        
        float kShine1 = pow(clamp(- distance(vUv, uv1) / radius1 + 1.0,0.0,1.0), 1.0);
        vec4 shining1 = vec4(mix(vec3(0.0),vec3(1.0), kShine1), 0.0) * 1.2 * pixel;
        
        vec2 uv2 = vec2(center2.x, 0.998 - center2.y) * 2.0 - 0.25;
        uv2.x -= 0.28;
        
        float kShine2 = pow(clamp(- distance(vUv, uv2) / radius2 + 1.0,0.0,1.0), 1.0);
        vec4 shining2 = vec4(mix(vec3(0.0),vec3(1.0), kShine2),0.0) * 1.2 * pixel;


        gl_FragColor = pixel + shining1 + shining2;
    }
`;

export class HeroLogoWebGL {
    width = SIZE.x;
    height = SIZE.y;
    canvas = document.createElement('canvas');
    gl = this.canvas.getContext('webgl', {
        premultipliedAlpha: false,
        alpha: true
    });
    drawPlane = planeCreator(this.gl);
    image = assets[0];
    mainTexture = textureCreator(this.gl, assets[0]);
    baseShader = new GLProgram(this.gl, baseVertex, baseFragment);

    constructor() {
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        this.render(0);
    }

    render = (time) => {
        requestAnimationFrame(this.render);

        this.gl.viewport(0, 0, this.width, this.height);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT);

        this.gl.blendFunc(this.gl.SRC_ALPHA , this.gl.ONE_MINUS_SRC_ALPHA);
        this.gl.enable(this.gl.BLEND);

        this.baseShader.bind();
        this.gl.uniform2fv(this.baseShader.uniforms.resolution, [
            this.width,
            this.height
        ]);

        const c = calculateCoords(time, 2000.0, 14.0);
        this.gl.uniform2fv(this.baseShader.uniforms.center1, [c.center1.x, c.center1.y]);
        this.gl.uniform2fv(this.baseShader.uniforms.center2, [c.center2.x, c.center2.y]);

        this.gl.uniform1f(this.baseShader.uniforms.time, time / 4000.0);
        this.mainTexture.attach();
        this.drawPlane();
    }
}
