const sendEmail = async (name, email, message) => {
   const res = await fetch('https://mailer.theevenstar.net', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_name: name,
      user_email: email,
      message,
    })
  });
  return res
};

export default sendEmail;