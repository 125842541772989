import React from 'react';
import { string, shape } from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Feature, contentPropTypes } from '../Feature';

export const WhatWeDo = ({ content, image }) => (
    <div id="what-we-do" className="what-we-do">
        <Feature appearDelay={600} { ...content } />
        <div className="what-we-do__image what-we-do-image">
            <Fade top distance="60px">
                <div className="what-we-do-image__background"></div>
            </Fade>
            <Fade top distance="30px" delay={200}>
                <div
                    className="what-we-do-image__content"
                    style={{ backgroundImage: `url(${image})` }}
                ></div>
            </Fade>
        </div>
    </div>
);

WhatWeDo.propTypes = {
    content: shape(contentPropTypes),
    image: string,
};
