import React from 'react';
import PropTyepes from "prop-types";
import Carousel from 'nuka-carousel';

import { contentPropTypes, Feature } from "../Feature";

import { ReactComponent as ArrowLeft } from '../../assets/icon-arrow-left-light.svg'
import { ReactComponent as ArrowRight } from '../../assets/icon-arrow-right-light.svg'

import './OurTeam.scss';
import MobileCarousel from "./MobileCarousel";

class Index extends React.Component {
  static propTypes = {
    content: PropTyepes.shape(contentPropTypes),
  };

  renderLeftControl = ({ previousSlide }) => (
    <div className="controls-left" onClick={previousSlide}>
      <ArrowLeft/>
    </div>
  );

  renderRightControl = ({ nextSlide }) => (
    <div className="controls-right" onClick={nextSlide}>
      <ArrowRight/>
    </div>
  );

  getItemsToScroll = () => {
    if (window.innerWidth < 1024) {
      return 1;
    }

    if (window.innerWidth < 1400) {
      return 2;
    }

    return 3;
  };

  get carousel() {
    const content = this.props.employeePhotos.map((photo, index) => (
        <div className="carousel-item" key={photo.id}>
          <img className="carousel-item-photo" src={photo.photo_url} alt=""/>
          <div className="carousel-item-name">
            {photo.name}
          </div>
          <div className="carousel-item-position">
            {photo.position}
          </div>
        </div>
      )
    );

    if (window.innerWidth <= 767) {
      return (
        <MobileCarousel>
          {content}
        </MobileCarousel>
      )
    }

    return (
      <Carousel
        slidesToShow={this.getItemsToScroll()}
        slidesToScroll={1}
        renderCenterLeftControls={this.renderLeftControl}
        renderCenterRightControls={this.renderRightControl}
        autoplay
        pauseOnHover
        wrapAround
        disableEdgeSwiping
        resetWrapAroundPosition
        width="80%"
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: '#535777'
          }
        }}
      >
        {content}
      </Carousel>
    )
  }

  render() {
    const { content } = this.props;

    return (
      <div id="team" className="our-team">
        <div className="our-team__feature">
          <Feature
            {...content}
            subtitle="Professionals you can trust"
          />
        </div>
        <div className="slider-photos">
          {this.carousel}
        </div>
      </div>
    )
  }
}

export default Index;

