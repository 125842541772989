import React from 'react';
import { Modal } from '../Modal';
import CheckMark from '../../assets/check-mark.png';
import { ReactComponent as ArrowRight } from '../../assets/icon-arrow-right-light.svg';
import './ContactUsModal.scss';

export const ContactUsModal =
  ({
     showModal,
     success,
     error: {
       emailError,
       nameError,
       messageError,
     },
     handleToggleModal,
     handleInputChange,
     handleSendEmail,
   }) => {
    return (
      <Modal
        show={showModal}
        onClose={handleToggleModal}
      >
        {success
          ? (
            <div className="thank-you-message">
              <img src={CheckMark} alt=""/>
              <div>
                <span className="title">We have received your message.</span>
                <div className="description">
                <span>Thank you.</span>
                <span>We will consider it and come back to you soon.</span>
                </div>
              </div>

              <button onClick={handleToggleModal}>
                OK
              </button>
            </div>
          ) : (
            <div className="contact-us-content">
              <div
                className="contact-us-content-close"
                onClick={handleToggleModal}
              >
                X
              </div>
              <h1 className="contact-us-content-title">
                Contact us
              </h1>
              <div className="contact-us-content-inputs-wrapper">
                <div className="input-item">
                  <label htmlFor="name">
                    Name* {nameError && <span>{nameError}</span>}
                  </label>
                  <input
                    placeholder="name"
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-item">
                  <label htmlFor="email">
                    E-mail* {emailError && <span>{emailError}</span>}
                  </label>
                  <input
                    placeholder="E-mail"
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-item">
                  <label htmlFor="message">
                    Message* {messageError && <span>{messageError}</span>}
                  </label>
                  <textarea
                    placeholder="Message"
                    rows="4"
                    id="massage"
                    name="message"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div
                className="contact-us-content-button"
                onClick={handleSendEmail}
              >
                Send <ArrowRight/>
              </div>
            </div>
          )
        }
      </Modal>
    )
  };
