import React from 'react';
import { HeroNavigation } from '../HeroNavigation';
import { HeroTitle } from '../HeroTitle';
import { HeroBackground } from '../HeroBackground';
import { HeroMobileNavigation } from '../HeroMobileNavigation';
// import logo from '../../../assets/evenstar-logo.svg';
import {HeroLogo} from "../HeroLogo";

const handleClick = (event) => {
    event.preventDefault();
    const { target: { href } } = event;
    const id = href.split('#')[1];

    const section = document.getElementById(id);

    if (section) {
        const { offsetTop } = section;
        window.scrollTo({
            top: offsetTop,
            left: 0,
            behavior: 'smooth',
        });
    }
};

export const HeroContainer = ({ links, email }) => (
    <header className="hero">
        <div className="hero-content">
            <div className="hero__nav">
                <HeroLogo/>
                <HeroNavigation onClick={handleClick} />
                <HeroMobileNavigation
                    onClick={handleClick}
                    links={links}
                    email={email}
                />
            </div>
            <HeroTitle onCTAClick={handleClick} />
        </div>
        <HeroBackground enabled={true} />
    </header>
);
