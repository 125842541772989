import React from 'react';

import blueRect from '../../assets/parts/blue-rectangular.svg';
import darkBlueSphere from '../../assets/parts/dark-blue-sphere.svg';
import lightPinkSphere from '../../assets/parts/light-pink-sphere.svg';
import lightBlueSphere from '../../assets/parts/light-blue-sphere.svg';
import pinkArrow from '../../assets/parts/pink-arrow.svg';
import pinkRect from '../../assets/parts/pink-rectangular.svg';
import blueCube from '../../assets/parts/blue-cube.svg';
import darkBlueRect from '../../assets/parts/darker-blue-rectangular.svg';
import darkPinkSphere from '../../assets/parts/dark-pink-sphere.svg';

import {Effect} from "./Effect";

const EFFECTS = [
    blueRect,
    pinkRect,
    darkBlueSphere,
    lightPinkSphere,
    lightBlueSphere,
    pinkArrow,
    blueCube,
    darkBlueRect,
    darkPinkSphere
];

const EFFECTS_COUNT = EFFECTS.length;
const EFFECTS_COUNT_PER_PAGE = 30;

export class FullPageEffects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 100
        };

        window.addEventListener('resize', this.update);
    }

    getOffsetSum = (elem) => {
        let top=0, left=0;
        while(elem) {
            top = top + parseFloat(elem.offsetTop);
            left = left + parseFloat(elem.offsetLeft);
            elem = elem.offsetParent
        }

        return {top: Math.round(top), left: Math.round(left)}
    };

    componentDidMount = () => {
        this.update();
    };

    getRandomEffect = () => {
        return EFFECTS[Math.floor(Math.random() * EFFECTS_COUNT)];
    };

    update = () => {
        let footer = document.querySelector('footer#footer');
        if (footer) {
            this.setState({
                height: this.getOffsetSum(footer).top - window.innerHeight
            });
        }
    };

    generateRandomEffects = () => {
        let effects = [];

        let currentY = 0;
        let currentX, size;

        for(let i = 0; i < EFFECTS_COUNT_PER_PAGE; i++) {
            currentX = Math.floor(Math.random() * 100);
            size = Math.max(1.0, Math.floor(Math.random() * 8));
            effects.push(
                <Effect src={this.getRandomEffect()} x={currentX} y={currentY} key={'effect' + i} size={size} imgSize={size * 20}/>
            );
            currentY = Math.round((i / EFFECTS_COUNT_PER_PAGE) * 140);
        }

        return effects;
    };

    render = () => {
        let style = {
            height: `${this.state.height}px`
        };
        return (
            <div style={style} className="section-effect">
                {/*<Effect src={darkBlueSphere} x={10} y={4} imgSize={20}/>*/}
                {/*<Effect src={blueRect} x={2} y={7} size={5} imgSize={60}/>*/}
                {/*<Effect src={lightPinkSphere} x={40} y={10} size={2} imgSize={30}/>*/}
                {/*<Effect src={pinkArrow} x={14} y={26} size={7} imgSize={160}/>*/}


                {/*<Effect src={lightPinkSphere} x={45} y={20} size={1} imgSize={16}/>*/}
                {/*<Effect src={darkBlueSphere} x={40} y={24} size={3} imgSize={24}/>*/}
                {/*<Effect src={lightBlueSphere} x={52} y={30} size={6} imgSize={30}/>*/}

                {/*<Effect src={darkBlueSphere} x={78} y={40} size={5} imgSize={40}/>*/}
                {/*<Effect src={lightPinkSphere} x={56} y={40} size={2} imgSize={24}/>*/}
                {/*<Effect src={pinkRect} x={90} y={56} size={8} imgSize={180}/>*/}
                {/*{this.generateRandomEffects()}*/}
            </div>
        );
    };
}
