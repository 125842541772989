import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import Fade from 'react-reveal/Fade';
import { withSlider, sliderPropTypes } from '../SliderHOC';
import { SliderControls } from '../SliderControls';
import {TestimotionalWebGlPlaceholder} from "../TestimotionalWebGlPlaceholder";

export const testimonialPropType = shape({
    author: string,
    position: string,
    content: string,
    photo: string,
});

class Slider extends React.Component {
    static propTypes = {
        ...sliderPropTypes,
        slides: arrayOf(testimonialPropType),
    };

    formatAmount = amount => amount < 10 ? `0${amount}` : amount;

    renderSlideCounter = (current, total) => {
        const formattedCurrent = this.formatAmount(current + 1);
        const formattedTotal = this.formatAmount(total);
        const content = `${formattedCurrent} / ${formattedTotal}`;

        return (
            <span className="slide-counter">{content}</span>
        );
    }

    render() {
        const {
            currentSlide,
            slides,
            onNextSlide,
            onPrevSlide,
        } = this.props;
        const slide = slides[currentSlide];

        return (
            <div className="testimonials">
                <div className="feature__header feature-header testimonials__title">
                    <span className="feature-header__sub-title">We build long lasting relationships. We go the extra mile. What people say about us</span>
                </div>
                <div className="testimonials__list">
                    <div className="testimonials__photo">
                        <Fade appear>
                            <TestimotionalWebGlPlaceholder enabled={true}/>
                        </Fade>
                        {/*{slides.map((s, key) => (
                            <Fade
                                key={key}
                                spy={currentSlide}
                                when={s.author === slide.author}
                                appear
                            >
                                {
                                    s.photo
                                        ? <img src={s.photo} alt={s.author} />
                                        : <TestimotionalWebGlPlaceholder enabled={currentSlide === key}/>
                                }
                            </Fade>
                        ))}*/}
                        {this.renderSlideCounter(currentSlide, slides.length)}
                    </div>
                    <Fade delay={300} appear>
                        <div className="testimonials__content testimonials-content">
                            <div className="testimonials-controls">
                                <SliderControls
                                    onNext={onNextSlide}
                                    onPrev={onPrevSlide}
                                    inverted
                                />
                            </div>
                            <Fade delay={200} spy={currentSlide} top distance="50px" appear>
                                <p className="testimonials-content__title">{`’’${slide.content}’’`}</p>
                                <p className="testimonials-content__author">
                                    <span>{slide.author}</span>
                                    <span className="divider">
                                        &nbsp;
                                        &nbsp;
                                    </span>
                                    <span>{slide.position}</span>
                                </p>
                            </Fade>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}

export const Testimonials = withSlider(Slider);
