import React from 'react';
import {
  HeroContainer,
  Loader,
  Footer,
  WhatWeDo,
  Keywords,
  HowWeDo,
  AboutUs,
  OurWorks,
  Modal,
  ProjectDetails,
  Testimonials, OurTeam,
} from './components';
import { loadAssets } from "./components/Hero/HeroBackground/webgl/navier-stokes-solver";
import { FullPageEffects } from "./components/FullPageEffects";
import { loadLogo } from "./components/Hero/HeroLogo/webgl";

class App extends React.Component {
    state = {
        loading: true,
        socialLinks: [],
        content: {},
        email: '',
        keywords: [],
        platforms: [],
        projects: [],
        testimonials: [],
        whatWeDoImage: '',
        selectedProjectIndex: -1,
        hideModal: false,
        employeePhotos: [],
    };

    componentDidMount() {
        this.fetchContent();

        window.addEventListener('hashchange', this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading !== prevState.loading && !this.state.loading) {
            const hash = window.location.hash.replace('#', '');
            document.getElementById(hash)?.scrollIntoView();
        }
    }

    fetchContent = () => {
        this.setState({
            loading: true,
        }, () => {
            Promise.all([
                loadAssets(),
                loadLogo()
            ]).then(() => {
                fetch('/data.json')
                .then(response => response.json())
                .then(({
                    social_links,
                    email,
                    content,
                    keywords,
                    platforms,
                    projects,
                    testimonials,
                    what_we_do_image,
                    employee_photos,
                }) => {
                    setTimeout(() => {
                        this.setState({
                            loading: false,
                            socialLinks: social_links,
                            email,
                            content,
                            keywords,
                            platforms,
                            projects,
                            testimonials,
                            whatWeDoImage: what_we_do_image,
                            employeePhotos: employee_photos
                        }, () => {
                            const hash = window.location.hash.split('#')[1];

                            if (hash) {
                                this.setProjectFromHash(hash);
                            }
                        });
                    }, 1400);
                })
                .catch(error => console.error(error));
            });
        });
    };

    handleHashChange = event => {
        const hash = event.newURL.split('#')[1];

        if (hash) {
            this.setProjectFromHash(hash);
        } else {
            if (event.newURL !== event.oldURL) {
                this.handleCloseProject();
            }
        }
    };

    setProjectFromHash = hash => {
        const projectCode = hash.match(/^\/projects\/(\w+)/);
        const code = projectCode && projectCode[1];

        if (code && code.length) {
            const projectIndex = this.state.projects.findIndex(p => p.code === code);

            if (projectIndex > -1) {
                this.setState({
                    selectedProjectIndex: projectIndex,
                });
            }
        }
    };

    handleSelectProject = selectedProjectIndex => () => {
      this.setState({ selectedProjectIndex }, () => {
        const { projects, selectedProjectIndex } = this.state;
        const project = projects[selectedProjectIndex];

        window.history.pushState(null, null, `#/projects/${project.code}`);
      });
    };

    handleCloseProject = () => {
        this.setState({
            hideModal: true,
        }, () => {
            window.history.pushState(null, null, '#');

            setTimeout(() => {
                this.setState({
                    selectedProjectIndex: -1,
                    hideModal: false,
                });
            }, 1000);
        })
    };

    renderModal = () => {
        const {
            selectedProjectIndex,
            projects,
            hideModal,
        } = this.state;
        const selectedProject = selectedProjectIndex > -1
            && projects[selectedProjectIndex];

        return selectedProject && (
            <Modal
                key="project-details-modal"
                show={selectedProject}
                hide={hideModal}
                onClose={this.handleCloseProject}
            >
                <ProjectDetails
                    project={selectedProject}
                    onClose={this.handleCloseProject}
                />
            </Modal>
        );
    };

    render() {
        const {
            loading,
            email,
            socialLinks,
            content,
            keywords,
            platforms,
            projects,
            testimonials,
            whatWeDoImage,
            employeePhotos,
        } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
          <>
            <HeroContainer
              key="hero"
              links={socialLinks}
              email={email}
            />
            <FullPageEffects key="full-page-effects"/>
            <WhatWeDo
              key="what-we-do"
              content={content.what_we_do}
              image={whatWeDoImage}
            />
            <Keywords key="keywords" content={keywords} />
            <HowWeDo
              key="how-we-do"
              platforms={platforms}
              content={content.how_we_do}
            />
            {this.renderModal()}
            <OurWorks
              key="our-works"
              content={content.our_works}
              projects={projects}
              onSelectProject={this.handleSelectProject}
            />
            <Testimonials key="testimonials" slides={testimonials} />
            <br/>
            <OurTeam
              key="our-team"
              content={content.our_team}
              employeePhotos={employeePhotos}
            />
            <AboutUs key="about-us" content={content.about_us} />
            <Footer
              key="footer"
              links={socialLinks}
              email={email}
            />
          </>
        );
    }
}

export default App;
