import vertexSource from './shader/vertex.glsl';
import fragmentSource from './shader/fragment.glsl';

class SDFContainer {
    canvas;
    gl;
    parent = null;
    size = {x: 0, y: 0};
    renderEnabled = false;
    shadersCompiled = false;
    shaderProgram = null;
    vertexBuffer = null;
    attributes = {
        position: null
    };
    uniforms = {
        resolution: null,
        time: null
    };
    animationFrameId = null;
    appEnabled = true;

    constructor() {
        this.canvas = document.createElement('canvas');
        this.gl = this.canvas.getContext('experimental-webgl');

        this.canvas.addEventListener('webglcontextlost', (event) => {
            event.preventDefault();
            this.appEnabled = false;
            //document.location.reload(true);// eslint-disable-line no-restricted-globals
        });

        this.canvas.addEventListener('webglcontextrestored', () => {
            document.location.reload(true);
        }, false);

        window.addEventListener('resize', () => requestAnimationFrame(() => this.onResize()));
        window.addEventListener('scroll', () => requestAnimationFrame(() => this.onScroll()));

        this.init();
    }

    init = () => {
        let {gl} = this;
        if (!gl) {
            return false;
        }

        let vertices = [-1.0, 1.0, -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0, 1.0, -1.0];
        this.vertexBuffer = gl.createBuffer();

        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        Promise.all([
            fetch(vertexSource).then(response => response.text()),
            fetch(fragmentSource).then(response => response.text())
        ]).then(([vs, fs]) => {
            let vertShader = gl.createShader(gl.VERTEX_SHADER);
            gl.shaderSource(vertShader, vs);
            gl.compileShader(vertShader);

            let fragShader = gl.createShader(gl.FRAGMENT_SHADER);
            gl.shaderSource(fragShader, fs);
            gl.compileShader(fragShader);

            this.shaderProgram = gl.createProgram();

            gl.attachShader(this.shaderProgram, vertShader);
            gl.attachShader(this.shaderProgram, fragShader);
            gl.linkProgram(this.shaderProgram);
            //
            // let compilationLog = gl.getShaderInfoLog(vertShader);
            //
            // compilationLog = gl.getShaderInfoLog(fragShader);

            this.attributes.position = this.gl.getAttribLocation(this.shaderProgram, "coordinates");
            this.uniforms.resolution = gl.getUniformLocation(this.shaderProgram, "resolution");
            this.uniforms.time = gl.getUniformLocation(this.shaderProgram, "time");


            this.shadersCompiled = true;

            this.enabled = true;
        });
    };

    isInViewport = () => {
        let bounding = this.canvas.getBoundingClientRect();
        let x = bounding.left;
        let y = bounding.top;
        let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        let w = this.canvas.clientWidth;
        let h = this.canvas.clientHeight;
        return (
            (y < hw &&
                y + h > 0) &&
            (x < ww &&
                x + w > 0)
        );
    };

    set enabled(value) {
        if (this.renderEnabled === value) {
            return false;
        }

        if (!value && this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
        }

        this.renderEnabled = !!value;
        if (this.renderEnabled && this.shadersCompiled) {
            this.render(0);
        }
    }

    onScroll() {
        if (this.parent && this.shadersCompiled && this.appEnabled) {
            this.enabled = this.isInViewport();
        } else {
            this.enabled = false;
        }
    }

    onResize() {
        if (this.parent && this.appEnabled) {
            this.updateSize();
        }
    }

    updateSize() {
        this.canvas.width = this.parent.clientWidth;
        this.canvas.height = this.parent.clientHeight;
        this.size = {
            x: this.canvas.width,
            y: this.canvas.height
        };
    }

    setParent(parent) {
        this.parent = parent;
        parent.appendChild(this.canvas);
        this.updateSize();
        this.onScroll();
    }

    render(deltaTime) {
        if (!this.appEnabled) {
            return false;
        }

        if (this.renderEnabled) this.animationFrameId = requestAnimationFrame((dt) => this.render(dt));

        this.gl.useProgram(this.shaderProgram);
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);

        this.gl.vertexAttribPointer(this.attributes.position, 2, this.gl.FLOAT, false, 0, 0);
        this.gl.enableVertexAttribArray(this.attributes.position);

        this.gl.uniform2fv(this.uniforms.resolution,  [this.size.x, this.size.y]);
        this.gl.uniform1f(this.uniforms.time,  deltaTime / 1000.0);

        this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT);

        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
        this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
    }
}

export const SDF = new SDFContainer();