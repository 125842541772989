import React from 'react';
import { shape } from 'prop-types';
import { Feature, contentPropTypes } from '../Feature';

export const AboutUs = ({ content }) => (
    <div id="about-us" className="about-us">
        <Feature { ...content } />
    </div>
);

AboutUs.propTypes = {
    content: shape(contentPropTypes),
};
