import React from 'react'
import {isMobile, isMobileDevice} from "../../../common/device";

export default class LiquidButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.buttonWrapper = React.createRef();
  }
  componentDidMount() {
      let pointsA = [],
        pointsB = [],
        canvas = null,
        context = null,
        points = 8,
        viscosity = 20,
        mouseDist = 70,
        damping = 0.05,
        showIndicators = false,
        mouseX = 0,
        mouseY = 0,
        relMouseX = 0,
        relMouseY = 0,
        mouseLastX = 0,
        mouseLastY = 0,
        mouseDirectionX = 0,
        mouseDirectionY = 0,
        mouseSpeedX = 0,
        mouseSpeedY = 0;

      /**
       * Get mouse direction
       */
      const mouseDirection = (e) => {
        if (mouseX < e.pageX)
          mouseDirectionX = 1;
        else if (mouseX > e.pageX)
          mouseDirectionX = -1;
        else
          mouseDirectionX = 0;

        if (mouseY < e.pageY)
          mouseDirectionY = 1;
        else if (mouseY > e.pageY)
          mouseDirectionY = -1;
        else
          mouseDirectionY = 0;

        mouseX = e.pageX;
        mouseY = e.pageY;

        let rect = canvas.getBoundingClientRect();

        relMouseX = (mouseX - (rect.left + document.body.scrollLeft));
        relMouseY = (mouseY - (rect.top + document.body.scrollTop));
      }

      /**
       * Get mouse speed
       */
      const mouseSpeed = () => {
        mouseSpeedX = mouseX - mouseLastX;
        mouseSpeedY = mouseY - mouseLastY;

        mouseLastX = mouseX;
        mouseLastY = mouseY;

        setTimeout(mouseSpeed, 50);
      }
      mouseSpeed();

      /**
       * Init button
       */
      const initButton = () => {
        // Get button
        let button = this.buttonWrapper.current;
        let buttonWidth = button.offsetWidth;
        let buttonHeight = button.offsetHeight;

        // Create canvas
        canvas = document.createElement('canvas');
        button.appendChild(canvas);

        canvas.width = buttonWidth+100;
        canvas.height = buttonHeight+100;
        context = canvas.getContext('2d');

        // Add points

        let x = buttonHeight/2;
        for(let j = 1; j < points; j++) {
          addPoints((x+((buttonWidth-buttonHeight)/points)*j), 0);
        }
        addPoints(buttonWidth-buttonHeight/5, 0);
        addPoints(buttonWidth+buttonHeight/10, buttonHeight/2);
        addPoints(buttonWidth-buttonHeight/5, buttonHeight);
        for(let j = points-1; j > 0; j--) {
          addPoints((x+((buttonWidth-buttonHeight)/points)*j), buttonHeight);
        }
        addPoints(buttonHeight/5, buttonHeight);

        addPoints(-buttonHeight/10, buttonHeight/2);
        addPoints(buttonHeight/5, 0);

        renderCanvas();
      }

      /**
       * Add points
       */
      const addPoints = (x, y) => {
        pointsA.push(new Point(x, y, 1));
        pointsB.push(new Point(x, y, 2));
      }

      /**
       * Point
       */
      function Point(x, y, level) {
        this.x = this.ix = 50+x;
        this.y = this.iy = 50+y;
        this.vx = 0;
        this.vy = 0;
        this.cx1 = 0;
        this.cy1 = 0;
        this.cx2 = 0;
        this.cy2 = 0;
        this.level = level;
      }

      Point.prototype.move = function() {
        this.vx += (this.ix - this.x) / (viscosity*this.level);
        this.vy += (this.iy - this.y) / (viscosity*this.level);

        let dx = this.ix - relMouseX,
          dy = this.iy - relMouseY;
        let relDist = (1-Math.sqrt((dx * dx) + (dy * dy))/mouseDist);

        // Move x
        if ((mouseDirectionX > 0 && relMouseX > this.x) || (mouseDirectionX < 0 && relMouseX < this.x)) {
          if (relDist > 0 && relDist < 1) {
            this.vx = (mouseSpeedX / 4) * relDist;
          }
        }
        this.vx *= (1 - damping);
        this.x += this.vx;

        // Move y
        if ((mouseDirectionY > 0 && relMouseY > this.y) || (mouseDirectionY < 0 && relMouseY < this.y)) {
          if (relDist > 0 && relDist < 1) {
            this.vy = (mouseSpeedY / 4) * relDist;
          }
        }
        this.vy *= (1 - damping);
        this.y += this.vy;
      };

      const renderCanvas = () => {
        if (!(isMobile() || isMobileDevice())) {
          requestAnimationFrame(renderCanvas);
        }

        // Clear scene
        context.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
        context.fillStyle = 'transparent';
        context.fillRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);

        // Move points
        for (let i = 0; i <= pointsA.length - 1; i++) {
          pointsA[i].move();
          pointsB[i].move();
        }

        let rect = canvas.getBoundingClientRect();

        // Create dynamic gradient
        let gradientX = Math.min(Math.max(mouseX - (rect.left + document.body.scrollLeft), 0), canvas.offsetWidth);
        let gradientY = Math.min(Math.max(mouseY - (rect.top + document.body.scrollTop), 0), canvas.offsetHeight);
        let distance = Math.sqrt(Math.pow(gradientX - canvas.offsetWidth/2, 2) + Math.pow(gradientY - canvas.offsetHeight/2, 2)) / Math.sqrt(Math.pow(canvas.offsetWidth/2, 2) + Math.pow(canvas.offsetHeight/2, 2));

        let gradient = context.createRadialGradient(gradientX, gradientY, 300+(300*distance), gradientX, gradientY, 0);
        gradient.addColorStop(0, '#102ce5');
        gradient.addColorStop(1, '#E406D6');

        // Draw shapes
        let groups = [pointsA, pointsB]

        for (let j = 0; j <= 1; j++) {
          let points = groups[j];

          if (j === 0) {
            context.fillStyle = '#1CE2D8';
          } else {
            context.fillStyle = gradient;
          }

          context.beginPath();
          context.moveTo(points[0].x, points[0].y);

          for (let i = 0; i < points.length; i++) {
            let p = points[i];
            let nextP = points[i + 1];

            if (nextP !== undefined) {

              p.cx1 = (p.x+nextP.x)/2;
              p.cy1 = (p.y+nextP.y)/2;
              p.cx2 = (p.x+nextP.x)/2;
              p.cy2 = (p.y+nextP.y)/2;

              context.bezierCurveTo(p.x, p.y, p.cx1, p.cy1, p.cx1, p.cy1);
            } else {
              nextP = points[0];
              p.cx1 = (p.x+nextP.x)/2;
              p.cy1 = (p.y+nextP.y)/2;

              context.bezierCurveTo(p.x, p.y, p.cx1, p.cy1, p.cx1, p.cy1);
            }
          }

          context.fill();
        }

        if (showIndicators) {
          // Draw points
          context.fillStyle = '#000';
          context.beginPath();
          for (let i = 0; i < pointsA.length; i++) {
            let p = pointsA[i];

            context.rect(p.x - 1, p.y - 1, 2, 2);
          }
          context.fill();

          // Draw controls
          context.fillStyle = '#f00';
          context.beginPath();
          for (let i = 0; i < pointsA.length; i++) {
            let p = pointsA[i];

            context.rect(p.cx1 - 1, p.cy1 - 1, 2, 2);
            context.rect(p.cx2 - 1, p.cy2 - 1, 2, 2);
          }
          context.fill();
        }
      };

      // Init
      initButton();

      document.body.addEventListener('mousemove', mouseDirection);
  }

  render () {
    return (
      <div className="btn-liquid" ref={this.buttonWrapper}>
        <span className="inner">
          {this.props.children}
        </span>
      </div>
    )
  }
}