import React from 'react';
import {
    string,
    number,
    oneOfType,
    arrayOf,
} from 'prop-types';
import Fade from 'react-reveal/Fade';

const paragraphType = {
    PARAGRAPH: 'paragraph',
    LIST: 'list',
};

const renderDescription = (text, key) => {
    if (!text.type) {
        return <p key={key} className="feature__description">{text}</p>;
    }

    switch (text.type) {
        case paragraphType.PARAGRAPH:
            return (
                <p key={key} className="feature__description">{text.content}</p>
            );
        case paragraphType.LIST:
            return (
                <ul key={key} className="feature__description feature-description-list">
                    {text.content.map((item, index) => (
                        <li
                            className="feature-description-list__item"
                            key={key + index}
                        >{item}</li>
                    ))}
                </ul>
            );
        default:
            return null;
    }
};

export const Feature = ({
    number,
    title,
    subtitle,
    description,
    appearDelay,
}) => (
    <div className="feature">
        <Fade top distance="20px" delay={appearDelay}>
            <div className="feature__header feature-header">
                <span className="feature-header__number">{`${number}.`}</span>
                <span className="feature-header__divider"/>
                <span className="feature-header__sub-title">{subtitle}</span>
            </div>
        </Fade>
        <Fade top delay={appearDelay + 200} distance="40px">
            <h2 className="feature__title">{title}</h2>
        </Fade>
        <Fade top delay={appearDelay + 400} distance="60px">
            {description && (
              typeof description === 'string' ? (
                <p className="feature__description">{description}</p>
              ) : (
                description.map(renderDescription)
              )
            )}

        </Fade>
    </div>
);

export const contentPropTypes = {
    number: string.isRequired,
    title: string.isRequired,
    subtitle: string.isRequired,
    description: oneOfType([string, arrayOf(string)]),
    appearDelay: number,
};

Feature.propTypes = contentPropTypes;
Feature.defaultProps = {
    appearDelay: 0,
};
