import React from 'react';
import { string, arrayOf } from 'prop-types';
import Fade from 'react-reveal/Fade';
import { withSlider, sliderPropTypes } from '../SliderHOC';
import { SliderControls } from '../SliderControls';
import '../../common/device.min.css'

class Slider extends React.Component {
    static propTypes = {
        ...sliderPropTypes,
        slides: arrayOf(string),
        project: string.isRequired,
    };

    render() {
        const {
            project,
            slides,
            currentSlide,
            onClose,
        } = this.props;
        return (
            <div className="project-slider">
                <div className="project-slider__controls">
                    <SliderControls
                        onPrev={this.props.onPrevSlide}
                        onNext={this.props.onNextSlide}
                        onClose={onClose}
                    />
                </div>
                <div className="project-slider__macbook">
                  <div className="marvel-device macbook">
                    <div className="top-bar"/>
                    <div className="camera"/>
                    <div className="screen">
                      {/* content to show on screen */}
                      <Fade delay={300}>
                        <div className="project-slider-slides">
                          {slides.map((slide, key) => (
                            <Fade when={key === currentSlide}>
                              <img
                                key={key}
                                className="project-slider-slides__slide"
                                src={slide}
                                alt={project}
                              />
                            </Fade>
                          ))}
                        </div>
                      </Fade>
                    </div>
                    <div className="bottom-bar"/>
                  </div>
                </div>
              <div className="project-slider__image-wrapper">
                <Fade delay={300}>
                  <div className="project-slider-slides">
                    {slides.map((slide, key) => (
                      <Fade when={key === currentSlide}>
                        <img
                          key={key}
                          className="project-slider-slides__slide"
                          src={slide}
                          alt={project}
                        />
                      </Fade>
                    ))}
                  </div>
                </Fade>
              </div>
            </div>
        );
    }
}

export const ProjectSlider = withSlider(Slider);
