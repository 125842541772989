import React from 'react';
import {Parallax} from "./Parallax";

export class Effect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            x: props.x || 0,
            y: props.y || 0,
            x0: props.x || 0,
            y0: props.y || 0,
            size: props.size || 1
        };
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.onScroll);
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onScroll);
    };

    onScroll = () => {
        requestAnimationFrame(() => {
            let state = {...this.state};
            this.setState({
                ...state,
                ...Parallax(state.x0, state.y0, state.size)
            });
        });
    };

    render = () => {
        let style = {
            left: `${this.state.x}%`,
            top: `${this.state.y}%`,
            width: `${this.props.imgSize}px`
        };

        return (
            <img src={this.props.src} style={style} alt="effect" className="section-effect__effect"/>
        );
    };
}
