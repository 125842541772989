import React from 'react';
import PropTypes from 'prop-types';

export const withSlider = (Component) => {
    return class SliderWrapper extends React.Component {
        static propTypes = {
            slides: PropTypes.array.isRequired,
        };

        state = {
            currentSlide: 0,
        };

        handleNextSlide = () => {
            const { slides } = this.props;

            this.setState(({ currentSlide }) => ({
                currentSlide: currentSlide === slides.length - 1
                    ? 0
                    : currentSlide + 1,
            }));
        };

        handlePrevSlide = () => {
            const { slides } = this.props;

            this.setState(({ currentSlide }) => ({
                currentSlide: currentSlide === 0
                    ? slides.length - 1
                    : currentSlide - 1,
            }));
        };

        render() {
            const { currentSlide } = this.state;
            return (
                <Component
                    {...this.props}
                    onNextSlide={this.handleNextSlide}
                    onPrevSlide={this.handlePrevSlide}
                    currentSlide={currentSlide}
                />
            );
        }
    }
};

export const sliderPropTypes = {
    onNextSlide: PropTypes.func,
    onPrevSlide: PropTypes.func,
    currentSlide: PropTypes.number,
    slides: PropTypes.array,
};
