import React from 'react';
import {SDF} from "./SDFContainer";

export class TestimotionalWebGlPlaceholder extends React.Component {

    container = React.createRef();

    componentWillUpdate = (nextProps, nextState, nextContext) => {
        if (nextProps.enabled) {
            this.update();
        }
    };

    componentDidMount = () => {
        if (this.props.enabled) {
            this.update();
        }
    };

    update = () => {
        if (this.container && this.container.current) {
            SDF.setParent(this.container.current);
        }
    };

    render() {
        return (
            <div ref={this.container} className="testimonials__placeholder"/>
        );
    }
}
