import React from 'react';
import Fade from 'react-reveal/Fade';
import { func } from 'prop-types';
import { projectPropType } from '../Projects';
import { Feature } from '../Feature';
import { ProjectSlider } from '../ProjectSlider';

export class ProjectDetails extends React.Component {
    static propTypes = {
        project: projectPropType.isRequired,
        onClose: func.isRequired,
    }

    render() {
        const {
            project: {
                title,
                id,
                description,
                stack,
                screenshots,
                code,
            },
            onClose,
        } = this.props;

        return (
            <div className="project-details">
                <div className="project-details-screens">
                    <ProjectSlider
                        project={code}
                        slides={screenshots}
                        onClose={onClose}
                    />
                </div>
                <div>
                  <div className="project-details__details">
                    <Feature
                      number={id}
                      title={title}
                      description={description}
                      subtitle="What we’ve already done"
                    />
                  </div>
                  <Fade top delay={800}>
                    <div className="project-details-stack-container">
                      <div className="project-details-stack">
                        {stack.map((tech, key) => (
                          <span key={key} className="project-details-stack__tech">{tech}</span>
                        ))}
                      </div>
                    </div>
                  </Fade>
                </div>
            </div>
        );
    }
}
