import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowRight } from '../../assets/icon-arrow-right-light.svg';
import sendEmail from "../../api/api";
import { ContactUsModal } from "./ContactUsModal";
import { isSafari } from "../../common/device";

const year = new Date().getFullYear();

export class Footer extends React.Component {
  state = {
    showModal: false,
    email: '',
    name: '',
    message: '',
    error: {
      emailError: '',
      nameError: '',
      messageError: '',
    },
    success: false,
  };

  handleSendEmail = () => {
    const { email, name, message } = this.state;
    const emailValidator = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (email && name && message && emailValidator.test(email)) {
      sendEmail(name, email, message).then(() => {
        this.setState({ success: true })
      });
    } else {
      this.setState({
        error: {
          emailError: email !== '' && emailValidator.test(email) ? '' : !emailValidator.test(email) && email !== '' ? 'email is invalid' : 'this is required field',
          nameError: name !== '' ? '' : 'this is required field',
          messageError: message !== '' ? '' : 'this is required field',
        }
      })
    }
  };

  handleToggleModal = () => {
    const { showModal } = this.state;

    this.setState({
      showModal: !showModal,
      success: false,
      email: '',
      name: '',
      message: '',
      error: {
        emailError: '',
        nameError: '',
        messageError: '',
      },
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  render() {
    const { links, email, noID } = this.props;
    const { showModal, success, error } = this.state;

    return (
      <footer className="footer" id={noID ? '' : 'footer'}>
        <ContactUsModal
          success={success}
          showModal={showModal}
          error={error}
          handleToggleModal={this.handleToggleModal}
          handleInputChange={this.handleInputChange}
          handleSendEmail={this.handleSendEmail}
        />
        <div className="left">
          <div className={`contact-us-button  ${isSafari() ? '' : 'animated'}`} onClick={this.handleToggleModal}>
            <span className="content">
              contact us <ArrowRight/>
            </span>
            <div className="border"/>
          </div>
        </div>
        <div className="right">
          <div className="footer-links">
            {links.map(({ url, title }, key) => (
              <a className="footer-links__link" target="_blank" rel="noopener noreferrer" href={url} key={key}>
                <img
                  src={require(`../../assets/icon-${title}.svg`)}
                  alt={title}
                />
              </a>
            ))}
          </div>
          <p className="footer__text">
            Feel free to contact us. We would love to hear from you
            <br/>
            <a href={`mailto:${email}`}>{email}</a>
          </p>

          <p className="footer__copy">© The Evenstar, {year}, all rights reserved.</p>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  email: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  })),
};
