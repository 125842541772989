
export const Parallax = (oldX, oldY, speed) => {
    let scrollTop = document.documentElement.scrollTop;
    let scrollHeight = document.documentElement.scrollHeight;
    let scrollPercent = (scrollTop / scrollHeight) * 10 * speed;

    let y = oldY - scrollPercent;

    return {x: oldX, y};
};
